<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-21 16:58:20
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-08-16 10:36:43
 * @FilePath: /lcwpcvue/src/components/chat.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="chat-page">
    <div class="chat-navbar-view">
      <div
        class="nav"
        :class="listType == 4 ? 'nav-sl' : ''"
        @click="changeChatType(4)"
      >
        全部
      </div>

      <div
        class="nav"
        :class="listType == 2 ? 'nav-sl' : ''"
        @click="changeChatType(2)"
      >
        新招呼
      </div>
      <div
        class="nav"
        :class="listType == 1 ? 'nav-sl' : ''"
        @click="changeChatType(1)"
      >
        <el-badge :hidden="unreadNum == 0" is-dot class="nav-badge-item"
          >未读 <label v-if="unreadNum > 0">（{{
            unreadNum
          }}）</label> </el-badge
        >
      </div>
      <div
        class="nav"
        :class="listType == 3 ? 'nav-sl' : ''"
        @click="changeChatType(3)"
      >
        已沟通
      </div>
    </div>
    <div style="display: flex">
      <div class="chat-left-list-view">
        <div v-if="listType == 4" class="chat-list-keyword-input">
          <el-input
            placeholder="搜索名字或群聊"
            prefix-icon="el-icon-search"
            v-model="listSearchKeyWord"
            @input="toSearchList"
          >
          </el-input>
        </div>

        <span v-for="(item, index) in chatList" :key="item.to_id">
          <div
            class="chat-item"
            @click="changeChatUser(item, index)"
            :class="
              uid == item.to_id || isHoverId == item.to_id ? 'chat-item-sl' : ''
            "
            @mouseover="chatListItemMouseOver(item.to_id)"
            @mouseout="chatListItemMoseOut"
          >
            <el-badge
              :max="99"
              class="badge-num"
              :value="item.read"
              :hidden="item.read == 0"
            >
              <img class="user-avatar" :src="item.header_img" alt="" />
            </el-badge>
            <div class="user-name-newcont">
              <div class="user-name">
                <div class="name">{{ item.name }}</div>
                <div class="bz-text" v-if="item.label">{{ item.label }}</div>
              </div>
              <div class="new-conten">{{ item.content }}</div>
            </div>
            <div class="chat-time">{{ item.time }}</div>
          </div>
        </span>
      </div>

      <div class="chat-right-detail-view" v-if="msgList.length > 0">
        <div class="msg-ht-list" id="msg_page">
          <!-- <view class="times_msg">{{ msgList[0].timeStr }}</view> -->
          <!-- 企业卡片 -->
          <div
            style="margin-top: 30px; display: flex; justify-content: center"
            v-if="usertype == 1"
          >
            <cardindex
              :datainfo="leftinfo"
              :usertype="1"
              :time="msgList[0].timeStr"
              :fistuid="msgList[0].from"
            >
            </cardindex>
          </div>
          <!-- 求职者卡片 -->
          <div
            style="margin-top: 30px; display: flex; justify-content: center"
            v-if="usertype == 2"
          >
            <cardindex
              :datainfo="leftinfo"
              :usertype="2"
              :time="msgList[0].timeStr"
              :fistuid="msgList[0].from"
            >
            </cardindex>
          </div>
          <div
            class="msg_list"
            v-for="(item, index) in msgList"
            :id="'msg_' + index"
            :key="item.id"
          >
            <div class="times_msg" v-show="index == 0">
              {{ msgList[0].timeStr }}
            </div>
            <div v-if="index > 0">
              <div
                class="times_msg"
                v-if="item.time - msgList[index - 1].time > 200"
              >
                {{ item.timeStr }}
              </div>
            </div>
            <div class="times_msg" style="line-height: 30px" v-if="item.isYc">
              <span class="chmsg"
                >{{ item.to == uid ? "你" : "对方" }}撤回了一条消息</span
              >
            </div>
            <!-- 企业、求职者背景色 接收方id=uid -->
            <div
              :class="item.to == uid ? 'rightmsg' : 'leftmsg'"
              v-if="!item.isYc"
            >
              <!-- 操作框: 文本复制 撤回 添加到常用语 -->
              <div class="cz_box" v-if="czIndex == index">
                <div class="btns">
                  <div
                    v-if="item.type == 1"
                    class="its"
                    @click="addforCopy(item.content)"
                  >
                    <div>复制</div>
                  </div>
                  <div
                    class="its ddd"
                    v-if="item.type == 1"
                    @click="addforCyy(item.content)"
                  >
                    <div>添加常用语</div>
                  </div>
                  <div class="its" @click="withdrawMsg(item.id, index)">
                    <div>撤回</div>
                  </div>
                </div>
                <!-- <img src="@/assets/com_mr.png" mode="widthFix" /> -->
              </div>
              <!-- 对方头像 -->
              <span
                v-if="
                  item.type == 1 ||
                  (item.type == 3 && item.type_agree == 0) ||
                  (item.type == 4 && item.type_agree == 0) ||
                  (item.type == 9 && item.type_agree == 0) ||
                  item.type == 6 ||
                  item.type == 7
                "
              >
                <img
                  v-if="usertype == 2 && item.to == leftinfo.uid"
                  class="logo"
                  :src="leftinfo.chatavatar"
                />
                <img
                  v-if="usertype == 1 && item.to != leftinfo.uid"
                  class="logo"
                  :src="leftinfo.chatavatar"
                />
              </span>

              <div
                v-if="item.type == 1 || item.type == 6 || item.type == 7"
                class="msg"
                @click="bindLongmsg(item, index)"
                :class="
                  item.to == uid
                    ? usertype == 2
                      ? 'greenbj'
                      : 'whitebj'
                    : usertype == 2
                    ? 'whitebj'
                    : 'greenbj'
                "
              >
                <!-- 文本消息 -->
                <div class="tetx_box" v-if="item.type == 1">
                  <div v-for="(msgitem, i) in item.dataList" :key="i">
                    <span v-if="msgitem.type == 'txt'">{{ msgitem.msg }}</span>
                    <img
                      v-if="msgitem.type == 'emoji'"
                      class="emoji"
                      :src="msgitem.msg"
                    />
                    <!-- <img
                    v-if="msgitem.type == 'emoji'"
                    class="emoji"
                    src="@/assets/faces/ee_6.png"
                  /> -->
                  </div>
                </div>
                <!-- 音频 -->
                <div
                  class="audio_box"
                  v-if="item.type == 7 && !item.videoUrl && item.to == uid"
                >
                  <span>{{ item.long }}″</span>
                  <img
                    v-if="usertype == 1"
                    style="transition: 0.8s"
                    :style="{ opacity: !item.isBf ? 1 : 0.5 }"
                    src="@/assets/chat_icon8.png"
                  />
                  <img
                    v-if="usertype == 2"
                    style="transition: 0.8s"
                    :style="{ opacity: !item.isBf ? 1 : 0.5 }"
                    src="@/assets/chat_icon7.png"
                  />
                </div>
                <div
                  class="audio_box"
                  v-if="item.type == 7 && !item.videoUrl && item.to != uid"
                >
                  <img
                    v-if="usertype == 1"
                    style="transition: 0.8s"
                    :style="{ opacity: !item.isBf ? 1 : 0.5 }"
                    src="@/assets/chat_icon7.png"
                  />
                  <img
                    v-if="usertype == 2"
                    style="transition: 0.8s"
                    :style="{ opacity: !item.isBf ? 1 : 0.5 }"
                    src="@/assets/chat_icon8.png"
                  />
                  <span>{{ item.long }}″</span>
                </div>
                <!-- 视频 -->
                <div
                  class="video_box video-pl"
                  v-if="item.type == 7 && item.coverUrl"
                >
                  <img :src="item.coverUrl" mode="widthFix" />
                  <div class="play-img">
                    <img
                      style="width: 120px; height: 120px"
                      src="/static/images/zjd_detail_bf.png"
                      mode="widthFix"
                    />
                  </div>
                </div>
                <!--图片 -->
                <div class="video_box" v-if="item.type == 6">
                  <el-image
                    :src="item.url"
                    lazy
                    :preview-src-list="[item.url]"
                  ></el-image>
                  <!-- <img mode="widthFix" :src="item.url" /> -->
                  <!-- <video src="https://www.petzp.com//data/upload/appfile/20210416/1618562696.mp4"></video> -->
                </div>

                <!-- 位置地图 -->
                <!-- <div class="map_box" v-if="item.type == 8">
                <zczmap
                  :lat="item.mark[0].latitude"
                  :lng="item.mark[0].longitude"
                  :idname="'map' + item.id"
                  :mapwidth="item.mark[0].width"
                  :mapheight="item.mark[0].height"
                ></zczmap>
              </div> -->
                <!-- 我 面试邀请-->
              </div>
              <!-- 我的头像 -->
              <span
                v-if="
                  item.type == 1 ||
                  (item.type == 3 && item.type_agree == 0) ||
                  (item.type == 4 && item.type_agree == 0) ||
                  (item.type == 9 && item.type_agree == 0) ||
                  item.type == 6 ||
                  item.type == 7
                "
              >
                <img
                  v-if="usertype == 2 && item.to == rightinfo.uid"
                  class="logo"
                  :src="rightinfo.chatavatar"
                />
                <img
                  v-if="usertype == 1 && item.to != rightinfo.uid"
                  class="logo"
                  :src="rightinfo.chatavatar"
                />
              </span>
            </div>
            <span v-if="item.to == uid && !item.isYc">
              <!-- <div v-if="item.type == 1 ||
								item.type == 9 ||
								item.type == 6 ||
								item.type == 7 ||
								item.type == 8" class="statusright">已读</div> -->
              <div
                v-if="
                  item.type == 1 ||
                  item.type == 9 ||
                  item.type == 6 ||
                  item.type == 7
                "
                class="statusright"
                :class="item.status == 2 ? 'wd-status' : ''"
              >
                {{ item.status == 2 ? "送达" : "已读" }}
              </div>
            </span>
            <!-- <span v-if="item.to != uid">
					<div class="msg-status">未读</div>
				</span> -->
            <!-- 职位邀请 -->
            <div
              class="times_msg"
              style="line-height: 50px"
              v-if="item.type == 2"
            >
              {{
                item.to == uid
                  ? `${item.ext.jobcontent.name}职位已发送`
                  : "对方给你发送了职位邀请"
              }}
            </div>
            <div class="jobs_msg" v-if="usertype == 1 && item.type == 2">
              <cardindex2 :datainfo="item.ext.jobcontent"></cardindex2>
            </div>
            <!-- 面试邀请 接受了面试邀请  拒绝了面试邀请 -->
            <div
              :class="item.to == uid ? 'rightmsg' : 'leftmsg'"
              v-if="item.type == 5"
            >
              <!-- 对方头像 -->
              <img
                v-if="usertype == 2 && item.to == leftinfo.uid"
                class="logo"
                :src="leftinfo.chatavatar"
              />
              <img
                v-if="usertype == 1 && item.to != leftinfo.uid"
                class="logo"
                :src="leftinfo.chatavatar"
              />
              <div
                class="msg"
                :class="
                  item.to == uid
                    ? usertype == 2
                      ? 'greenbj'
                      : 'whitebj'
                    : usertype == 2
                    ? 'whitebj'
                    : 'greenbj'
                "
              >
                <div
                  class="interview_box"
                  v-if="item.type == 5"
                  @click="toInerviewPage(item.ext.interview_id)"
                >
                  <!-- <span
                  v-if="!item.ext.send_interview && item.to == uid"
                  class="icon"
                  >{{ item.ext.agree_interview == 1 ? "√" : "×" }}</span
                > -->
                  <img
                    v-if="
                      !item.ext.send_interview &&
                      item.ext.agree_interview &&
                      item.to != uid
                    "
                    style="width: 14px; height: 12px"
                    src="@/assets/chat_msjs.png"
                  />
                  <img
                    v-show="
                      !item.ext.send_interview &&
                      !item.ext.agree_interview &&
                      item.to != uid
                    "
                    style="width: 12px; height: 14px"
                    src="@/assets/chat_msjj.png"
                  />
                  <img
                    v-if="item.ext.send_interview && item.to != uid"
                    style="width: 14px; height: 14px"
                    src="@/assets/chat_msyq.png"
                  />
                  <span>
                    {{
                      item.ext.send_interview
                        ? (usertype == 2 ? "发送" : "收到") +
                          "了面试" +
                          (item.ext.usertype == 2 ? "邀" : "申") +
                          "请"
                        : item.ext.agree_interview == 1
                        ? "接受了面试邀请"
                        : "拒绝了面试邀请"
                    }}
                  </span>
                  <img
                    v-if="
                      !item.ext.send_interview &&
                      item.ext.agree_interview &&
                      item.to == uid
                    "
                    style="width: 14px; height: 12px"
                    src="@/assets/chat_msjs.png"
                  />
                  <img
                    v-if="
                      !item.ext.send_interview &&
                      !item.ext.agree_interview &&
                      item.to == uid
                    "
                    style="width: 12px; height: 14px"
                    src="@/assets/chat_msjj.png"
                  />
                  <img
                    v-if="item.ext.send_interview && item.to == uid"
                    style="width: 14px; height: 14px"
                    src="@/assets/chat_msyq.png"
                  />
                </div>
              </div>
              <img
                v-if="usertype == 2 && item.to == rightinfo.uid"
                class="logo"
                :src="rightinfo.chatavatar"
              />
              <img
                v-if="usertype == 1 && item.to != rightinfo.uid"
                class="logo"
                :src="rightinfo.chatavatar"
              />
            </div>
            <div
              class="times_msg"
              style="line-height: 30px"
              v-if="usertype == 1 && item.type == 10"
            >
              你已成功发送简历给对方
            </div>
            <div
              class="times_msg"
              style="line-height: 30px"
              v-if="usertype == 2 && item.type == 10"
            >
              <!-- 求职卡片 -->
              <div style="margin-top: 30px" v-if="usertype == 2 && msgList[0]">
                <cardindex
                  :datainfo="leftinfo"
                  :usertype="2"
                  :time="msgList[0].timeStr"
                ></cardindex>
              </div>
            </div>
            <!-- 发送交换微信，电话请求 提示文字 -->
            <div
              class="times_msg"
              style="line-height: 30px"
              v-if="
                item.to == uid &&
                (item.type == 3 || item.type == 9 || item.type == 4) &&
                item.type_agree == 0
              "
            >
              {{
                item.type == 3
                  ? "请求交换电话已发送"
                  : item.type == 9
                  ? "请求交换电话已发送"
                  : "请求交换微信已发送"
              }}
            </div>
            <!-- <div class="times_msg" style="line-height:30px;"
					v-if="item.to == uid && (item.type == 3 || item.type == 9 || item.type == 4) && item.type_agree == 1">
					{{ item.type == 3 ? '已同意交换电话' : item.type == 9 ? '已同意交换电话' : '已同意交换微信' }}
				</div> -->
            <div
              class="times_msg"
              style="line-height: 30px"
              v-if="
                item.to == uid &&
                (item.type == 3 || item.type == 9 || item.type == 4) &&
                item.type_agree == 2
              "
            >
              {{
                item.type == 3
                  ? "您已成功拒绝对方交换电话的请求"
                  : item.type == 9
                  ? "您已成功拒绝对方交换电话的请求"
                  : "您已成功拒绝对方交换微信的请求"
              }}
            </div>
            <div
              class="times_msg"
              style="line-height: 30px"
              v-if="
                item.to != uid &&
                (item.type == 3 || item.type == 9 || item.type == 4) &&
                item.type_agree == 2
              "
            >
              {{
                item.type == 3
                  ? "对方已拒绝对方交换电话的请求"
                  : item.type == 9
                  ? "对方已拒绝对方交换电话的请求"
                  : "对方已拒绝对方交换微信的请求"
              }}
            </div>
            <div
              class="times_msg"
              style="line-height: 30px"
              v-if="item.to != uid && item.type == 2"
            ></div>
            <!-- 申请中 收到的交换微信，电话  -->
            <div
              class="receivedjh_box"
              v-if="
                item.to != uid &&
                (item.type == 3 || item.type == 9 || item.type == 4) &&
                item.type_agree == 0
              "
            >
              <img class="logo" src="" style="opacity: 0" />
              <div>
                <div class="tops">
                  为了方便联系，能交换{{
                    item.type == 3 || item.type == 9 ? "电话" : "微信号"
                  }}吗?
                </div>
                <div class="btns" v-if="!item.agreestutas">
                  <span
                    @click="
                      changeNums(
                        item.id,
                        2,
                        item.type == 3 || item.type == 9 ? 'moblie' : 'wxnum'
                      )
                    "
                    >拒绝</span
                  >
                  <span
                    @click="
                      changeNums(
                        item.id,
                        1,
                        item.type == 3 || item.type == 9 ? 'moblie' : 'wxnum'
                      )
                    "
                    >同意</span
                  >
                </div>
                <div class="btns" v-if="item.agreestutas">
                  <span style="color: #cccccc">拒绝</span>
                  <span style="color: #cccccc">同意</span>
                </div>
              </div>
            </div>
            <!-- 收到的微信号 -->
            <div
              class="num_box"
              v-if="item.to != uid && item.type == 4 && item.type_agree == 1"
            >
              <div class="tops">
                <span>{{ uNumInfo.name }}的微信号：</span>
                <label>{{ uNumInfo.wxnumjm }}</label>
              </div>
              <div
                class="btns"
                v-clipboard:copy="uNumInfo.wxnumjm"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <span>复制微信号</span>
              </div>
            </div>

            <div
              class="num_box"
              v-if="item.to == uid && item.type == 4 && item.type_agree == 1"
            >
              <div class="tops">
                <img src="@/assets/img/chat1.png" />
                <div>
                  <span>{{ uNumInfo.name }}的微信号</span>
                  <div>{{ uNumInfo.wxnumjm }}</div>
                </div>
              </div>
              <div
                class="btns"
                v-clipboard:copy="uNumInfo.wxnumjm"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <span>复制微信号</span>
              </div>
            </div>
            <!-- 收到的手机号 -->
            <div
              class="num_box"
              v-if="
                item.to != uid &&
                (item.type == 3 || item.type == 9) &&
                item.type_agree == 1
              "
            >
              <div class="tops">
                <!-- <img
                :src="
                  uNumInfo.logo ? uNumInfo.logo : '/pagesA/static/img/chat1.png'
                "
              /> -->
                <div>
                  <span>{{ uNumInfo.name }}电话：</span>
                  <div>{{ uNumInfo.phonejm }}</div>
                </div>
              </div>
              <div
                class="btns"
                v-clipboard:copy="uNumInfo.phonejm"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <span>复制</span>
              </div>
            </div>
            <div
              class="num_box"
              v-if="
                item.to == uid &&
                (item.type == 3 || item.type == 9) &&
                item.type_agree == 1
              "
            >
              <div class="tops">
                <!-- <img src="/pagesA/static/img/chat1.png" /> -->
                <div>
                  <span>{{ uNumInfo.name }}电话：</span>
                  <div>{{ uNumInfo.phonejm }}</div>
                </div>
              </div>
              <div
                class="btns"
                v-clipboard:copy="uNumInfo.phonejm"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <span>复制</span>
              </div>
            </div>
            <!-- PC位置信息 -->
            <div class="map_box" v-if="item.type == 8">
              <div
                class="zcz-map"
                @click="
                  bigMapShowFun(item.mark[0].latitude, item.mark[0].longitude)
                "
              >
                <div class="add-text-view">
                  <img src="@/assets/jobinf_addr_img.png" alt="" />
                  <span>{{ item.mark[0].callout.content }}</span>
                </div>
                <zczmap
                  :lat="item.mark[0].latitude"
                  :lng="item.mark[0].longitude"
                  :idname="'map' + item.id"
                  :mapwidth="item.mark[0].width"
                  :mapheight="item.mark[0].height"
                  style="margin-left: 24px; margin-top: 10px"
                ></zczmap>
              </div>
            </div>
            <!-- 求职端拨打电话提示 -->
            <!-- <div class="qzphonekp" v-if="usertype == 1 && item.type == 11">
          <div class="tops">
            <img src="/static/images/chat_qzbddh.png" />
            <div>
              <text>对方对您很感兴趣，\n</text>
              <text>期待您随时电话联系</text>
            </div>
          </div>
          <div class="btns">
            <text @click="lookTell(uNumInfo.phone)">打电话</text>
          </div>
        </div>
        <div v-if="usertype == 1 && item.type == 12" class="bj-resume">
          <div class="top-v">
            <img src="/static/images/chat_bjjlimg.png" />
            <text>您的信息有缺失，完善后对方回复更快</text>
          </div>
          <div
            class="bot-v"
            data-url="/pagesA/user/resume/resume"
            @click="todetails"
          >
            编辑信息
          </div>
        </div> -->
          </div>
        </div>
        <div class="bot-send-input-btn">
          <div class="func-btn-view">
            <el-popover
              @show="getChatCyyList"
              placement="top"
              width="400"
              trigger="click"
              offset="-180"
              v-model="cyyPopoverShow"
            >
              <div class="pop-cyy-view">
                <div class="pop-cyy-title">
                  <span class="title">常用语</span>
                  <span class="set-text" @click="showCyyDialogFun">设置</span>
                </div>

                <div class="cyy-item-view">
                  <label v-for="item in cyyList" :key="item.id">
                    <div class="item" @click="sendCyyMsyFun(item)">
                      <span class="dian"></span>
                      <span class="text">{{ item.content }}</span>
                    </div>
                  </label>
                </div>
              </div>
              <img
                slot="reference"
                class="chyo-img-btn"
                src="@/assets/chat_chcy_btn.png"
              />
            </el-popover>
            <el-popover
              placement="top"
              width="400"
              trigger="click"
              offset="-200"
            >
              <emoji @newEmojiStr="sendEmoji"></emoji>
              <img
                slot="reference"
                class="em-img-btn"
                src="@/assets/chat_chose_emoji.png"
                alt=""
              />
            </el-popover>

            <el-upload
              :action="uploadUrl"
              :auto-upload="true"
              :multiple="false"
              :show-file-list="false"
              :on-success="uploadSuccess"
            >
              <img
                style="margin-right: 16px"
                class="em-img-btn"
                src="@/assets/chat_chimg_btnimg.png"
                alt=""
              />
            </el-upload>

            <el-divider direction="vertical"></el-divider>

            <div
              class="chat-act-view"
              @click="changeWxNumber(btnsStatus.wxnum)"
            >
              <img src="@/assets/chat/chat_chagewx.png" alt="" />
              <span>换微信</span>
            </div>
            <div
              class="chat-act-view send-phone"
              @click="changePhoneNumber(btnsStatus.moblie)"
            >
              <img src="@/assets/chat/chat_chageph.png" alt="" />
              <span>换电话</span>
            </div>
            <div
              class="chat-act-view send-resume"
              v-if="usertype == 1"
              @click="sendResume"
            >
              <img src="@/assets/chat/chat_ymsimg.png" alt="" />
              <span>发简历</span>
            </div>
            <div
              class="chat-act-view send-resume"
              v-if="usertype == 2 && btnsStatus.interview != 2"
              @click="interviewUserToCom(btnsStatus.interview)"
            >
              <img src="@/assets/chat/chat_ymsimg.png" alt="" />
              <span>约面试</span>
            </div>
            <div
              class="chat-act-view send-job"
              v-if="usertype == 2"
              @click="showChoseJobDialogFun"
            >
              <img src="@/assets/chat/chat_sendjob.png" alt="" />
              <span>发职位</span>
            </div>
            <div
              class="chat-act-view send-addr"
              v-if="usertype == 2"
              @click="choseAddrDialogShow = true"
            >
              <img src="@/assets/chat/chat_sendaddr.png" alt="" />
              <span>发位置</span>
            </div>
            <div
              class="chat-act-view set-bz"
              v-if="usertype == 2 && !isHaveBz"
              @click="addRemarks"
            >
              <img src="@/assets/chat/chat_setbz.png" alt="" />
              <span>设备注</span>
            </div>
            <div
              class="chat-act-view set-bz"
              v-if="usertype == 2 && isHaveBz"
              @click="addRemarks"
            >
              <img src="@/assets/chat/chat_setbz.png" alt="" />
              <span>已备注</span>
            </div>
            <!-- <img
            class="change-btn-img"
            src="@/assets/chat_change_wx.png"
            alt=""
            @click="changeWxNumber(btnsStatus.wxnum)"
          />
          <img
            class="change-btn-img"
            src="@/assets/chat_change_phone.png"
            alt=""
            @click="changePhoneNumber(btnsStatus.moblie)"
          />
          <img
            v-if="usertype == 1"
            class="change-btn-img"
            src="@/assets/chat_change_resume.png"
            alt=""
            @click="sendResume"
          />
          <img
            v-if="usertype == 2 && btnsStatus.interview != 2"
            class="change-btn-img"
            src="@/assets/chat_yms_img.png"
            alt=""
            @click="interviewUserToCom(btnsStatus.interview)"
          />
          <img
            v-if="usertype == 2"
            class="change-btn-img"
            src="@/assets/chat_sendjob_img.png"
            alt=""
            @click="showChoseJobDialogFun"
          />
          <img
            v-if="usertype == 2"
            class="change-btn-img"
            src="@/assets/chat_send_addr.png"
            alt=""
            @click="choseAddrDialogShow = true"
          />
          <img
            v-if="usertype == 2 && isHaveBz"
            class="change-btn-img"
            src="@/assets/chat_yszbz.png"
            alt=""
            @click="addRemarks"
          />
          <img
            v-if="usertype == 2 && !isHaveBz"
            class="change-btn-img"
            src="@/assets/chat_szbz.png"
            alt=""
            @click="addRemarks"
          /> -->
          </div>
          <div class="input-view">
            <el-input
              v-model="sendTextareaText"
              rows="4"
              type="textarea"
              maxlength="9999"
              @keydown.enter.native="enterSendFun"
            ></el-input>
            <el-button
              class="send-btn"
              type="primary"
              :disabled="sendBtnDis"
              @click="btnSendMsg"
              >发送</el-button
            >
          </div>
        </div>
      </div>

      <div class="chat-right-detail-view-nonelist" v-if="msgList.length == 0">
        <img class="none-list-img" src="@/assets/coupon_none_list.png" alt="" />
        <div class="none-text">当前暂无聊天</div>
      </div>
    </div>

    <!-- <div class="sys-msg-view" v-if="uid == 0">
      <label v-for="item in systemMsgList" :key="item.id">
        <div class="list">
          <div class="tit containers">
            <img class="ld-img" src="@/assets/qbtz.png" mode="" />
            <span
              class="tz"
              :class="item.is_read == 0 ? 'current' : ''"
              @click="tode"
              :data-uid="item.link_id"
              :data-url="
                '/pagesA/notice/notice_details?title=' +
                item.title +
                '&describes=' +
                item.desc
              "
              :data-id="item.id"
              >{{ item.title }}
            </span>
            <img
              class="ic"
              src="@/assets/chat_msjj.png"
              @click="delone(item.id)"
            />
          </div>
          <div
            class="text containers"
            @click="tode"
            :data-uid="item.link_id"
            :data-url="
              '/pagesA/notice/notice_details?title=' +
              item.title +
              '&describes=' +
              item.desc
            "
            :data-id="item.id"
            :data-type="item.type"
            :data-jbid="item.target_id"
          >
            {{ item.desc }}
          </div>
          <div
            class="todetails containers"
            @click="tode"
            :data-uid="item.link_id"
            :data-url="
              '/pagesA/notice/notice_details?title=' +
              item.title +
              '&describes=' +
              item.desc
            "
            :data-id="item.id"
            :data-type="item.type"
            :data-jbid="item.target_id"
          >
            <span class="time">{{ item.time }}</span>
            <div style="font-size: 14px; color: #fe6002; margin-right: 8px">
              查看详情
            </div>
          </div>
        </div>
      </label>
    </div> -->

    <el-dialog title="常用语" :visible.sync="cyyDialogShow">
      <div class="cyy-dialog-list-view">
        <label v-for="item in cyyList" :key="item.id">
          <div class="cyy-dialog-item-view">
            <div class="cyy-item-left-view">
              <span class="dian"></span>
              <span class="text">{{ item.content }}</span>
            </div>
            <div class="right-act-img-btn-view">
              <i class="el-icon-edit-outline" @click="editCyyFun(item)"></i>
              <i class="el-icon-delete" @click="delChatCyyList(item)"></i>
            </div>
          </div>
        </label>
        <div class="cyy-dialog-add-btn">
          <el-button @click="addCyyFun" type="primary" plain icon="el-icon-plus"
            >添加常用语</el-button
          >
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="面试日程"
      :visible.sync="interviewDialogShow"
      width="1003px"
      custom-class="inv-dialog-dialog"
    >
      <div class="interview-dialog">
        <div class="dialog-tiem-chose-view">
          <div class="dialog-title-view">
            <span class="left-xian-view"></span>
            <span class="text">面试时间</span>
          </div>

          <div class="time-view">
            <el-date-picker
              v-if="interviewDialogShow"
              ref="datePick"
              size="large"
              type="date"
              placeholder="选择日期"
              @change="chooseDate"
              @blur="chooseDate"
              v-model="interDay"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <el-time-select
              v-if="interviewDialogShow"
              ref="startTimePicker"
              placeholder="起始时间"
              v-model="interStaTime"
              :picker-options="{
                end: '18:00',
                start: '08:00',
                step: '01:00',
              }"
              @blur="chooseDate"
            >
            </el-time-select>
            <el-time-select
              v-if="interviewDialogShow"
              ref="endTimePicker"
              placeholder="结束时间"
              :picker-options="{
                start: '08:00',
                step: '01:00',
                end: '18:00',
                minTime: interStaTime,
              }"
              @blur="chooseDate"
              v-model="interEndTime"
            >
            </el-time-select>
          </div>
        </div>
        <div class="dialog-chose-job-view">
          <div class="dialog-title-view">
            <span class="left-xian-view"></span>
            <span class="text">面试岗位</span>
          </div>

          <div class="com-job-list">
            <label v-for="item in joblist" :key="item.id">
              <div
                class="job-item"
                :class="interviewMsgObj.jobid == item.id ? 'is-sl' : ''"
                @click.stop.capture="choseInterviewJob(item.id)"
              >
                {{ item.name }}
              </div>
            </label>
          </div>

          <div class="qr-btn">
            <el-button type="primary" @click="sendInterviewMsg"
              >发送面试邀请</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="请选择你已发布的职位"
      :visible.sync="sendJobDialogShow"
      width="25%"
    >
      <div class="dialog-job-list">
        <label v-for="item in jobsrange" :key="item.id">
          <div
            class="job-item"
            :class="sendJobInf.id == item.id ? 'is-chose-item' : ''"
            @click="choseSendJob(item)"
          >
            {{ item.name }}
          </div>
        </label>
      </div>
      <div class="dialog-bot-btn-vivew">
        <div class="btn-view" @click="sendJobDialogShow = false">取消</div>
        <div class="btn-view conf-btn" @click="sendJobInfMsg">确定</div>
      </div>
    </el-dialog>

    <!-- <choseaddressinmap
      :addAddrDialogShow="choseAddrDialogShow"
      @saveAddress="saveAddress"
      @closeMapPop="claseDia"
    ></choseaddressinmap> -->
    <choseaddress
      :addressDialogShow="choseAddrDialogShow"
      @submitAddress="submitAddress"
      @closeAddresPop="claseDia2"
    ></choseaddress>
    <showmapdialog
      :addAddrDialogShow="mapShowDialogShow"
      @closeMapPop="claseDia"
      :addressByXY="{ lat: bigmapLat, lng: bigmapLng }"
    ></showmapdialog>
    <!-- 系统消息 -->
    <el-drawer
      v-if="drawerShow"
      title="消息助手"
      :visible.sync="drawerShow"
      direction="rtl"
      :destroy-on-close="true"
      @close="drawerShow = false"
      infinite-scroll-disabled="disabled"
      :size="410"
    >
      <ul v-infinite-scroll="load">
        <label v-for="(item, index) in systemMsgList" :key="index">
          <div class="list" v-if="drawerShow">
            <div class="tit containers">
              <img class="ld-img" src="@/assets/qbtz.png" mode="" />
              <span
                v-if="item.title == '系统通知'"
                class="tz"
                :class="item.is_read == 0 ? 'current' : ''"
              >
                <el-badge :value="item.count" class="item-badge">{{
                  item.title
                }}</el-badge>
              </span>
              <span
                v-if="item.title != '系统通知'"
                class="tz"
                :class="item.is_read != 0 ? 'current' : ''"
              >
                <el-badge
                  :hidden="item.is_read != 0"
                  is-dot
                  class="item-badge"
                  >{{ item.title }}</el-badge
                >
              </span>
              <img
                v-if="item.title != '系统通知'"
                class="ic"
                src="@/assets/chat_msjj.png"
                @click="delMsg(2, item.id)"
              />
            </div>
            <div class="text containers">
              {{ item.desc }}
            </div>
            <div class="todetails containers" @click="toDetail(item)">
              <span class="time">{{ item.time }}</span>
              <div style="font-size: 14px; color: #fe6002; margin-right: 8px">
                查看详情
              </div>
            </div>
          </div>
        </label>
      </ul>
      <p v-if="noMore" style="width: 100%; text-align: center">没有更多了</p>
    </el-drawer>
    <el-drawer
      title="系统消息"
      :visible.sync="sonDrawerShow"
      @close="sonDrawerShow = false"
    >
      <ul v-infinite-scroll="load2">
        <div v-for="(item, index) in sysSonMsgList" :key="index">
          <div class="list2">
            <div class="tit containers">
              <img class="ld-img" src="@/assets/qbtz.png" mode="" />
              <span class="tz">
                <el-badge :hidden="item.is_read != 0" is-dot class="item-badge"
                  >系统通知</el-badge
                >
              </span>
              <img
                class="ic"
                src="@/assets/chat_msjj.png"
                @click="delMsg(1, item.id)"
              />
            </div>
            <div class="text containers">
              {{ item.content }}
            </div>
            <div class="todetails containers">
              <span class="time">{{ item.createtime }}</span>
              <div
                style="font-size: 14px; color: #fe6002; margin-right: 8px"
                @click="sonClickFun(item)"
              >
                查看详情
              </div>
            </div>
          </div>
        </div>
      </ul>
      <p style="width: 100%; text-align: center">没有更多了</p>
    </el-drawer>

    <el-dialog title="消息" :visible.sync="sysMsgDialogTableVisible">
      <div class="msg-detail">{{ msgDetail }}</div>
    </el-dialog>
    <el-dialog title="编辑备注" :visible.sync="addRemarksDialogTableVisible">
      <div class="remar-dialog-view">
        <div class="mk-title">标签：</div>
        <div class="remar-tag-view">
          <label v-for="item in remarkTagInfo.label_list" :key="item.id">
            <span
              :class="item.ischeck ? 'remar-tag-sl' : ''"
              @click="choseRemarTag(item.label)"
              >{{ item.label }}</span
            >
          </label>
          <span class="add-sp" @click="addRemarkTag">+ 添加标签</span>
        </div>
        <div class="mk-title">备注信息：</div>
        <div class="texter-view">
          <el-input
            rows="4"
            type="textarea"
            maxlength="9999"
            placeholder="请输入备注信息"
            v-model="bzTxt"
          ></el-input>
        </div>
        <div class="btn-view">
          <el-button type="primary" @click="saveRemarks">保存</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :title="payDialogTitle"
      :visible.sync="payDialogShow"
      class="vip-pay-dialog-css"
    >
      <div class="vip-type-view">
        <img class="vip-type-bg" src="@/assets/vip_pay_bg_img.png" alt="" />
        <div class="vip-type-text-view">
          <img class="lczx-img" src="@/assets/vip_pay_zx_img.png" alt="" />
          <div class="left-text-view">
            <div class="name-tt1">{{ payInf.name }}</div>
            <div class="time">{{ payInf.use }}</div>
          </div>
          <div class="right-text-view">
            <label>￥</label>{{ payInf.service_price }}
          </div>
        </div>
      </div>
      <div class="pay-type-view">
        <div class="paytype-title">支付方式</div>
        <div class="paytype">
          <div
            class="paytype-item"
            @click="changePayType('wxpay')"
            :class="payType == 'wxpay' ? 'item-sl' : ''"
          >
            <img src="@/assets/vip_paytype_wx_img.png" alt="" />
            <span>微信支付</span>
          </div>
          <div
            class="paytype-item"
            @click="changePayType('alipay')"
            :class="payType == 'alipay' ? 'item-sl' : ''"
          >
            <img src="@/assets/vip_paytype_zfb_img.png" alt="" />
            <span>支付宝支付</span>
          </div>
        </div>
      </div>
      <div class="wx-pay-img-view" v-show="payType == 'wxpay'">
        <div class="wxpay-tt1">请使用<label>微信</label>扫描二维码完成支付</div>
        <div class="wxpay-tt2">
          如无法支付或二维码已过期请点击<label>刷新</label>
        </div>
        <img class="wxpay-ewm-img" :src="wxPayImgUrl" alt="" />
        <div class="wxpay-tt2">
          购买付费即表示同意<label>《猎宠网VIP服务协议》</label>
        </div>
      </div>
      <div v-show="payType == 'alipay'" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toAliPayPage">去支付</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getChatList,
  getChatHistoryList,
  getChatUserInfo,
  getChatChangedInf,
  getChatCyyList,
  editChatCyyList,
  addChatCyyList,
  delChatCyyList,
  checkWxNumber,
  sedChatMsg,
  getChatWxnoPhnoInfo,
  changeWxPhStatus,
  getChatJobList,
  fistChatToMsg,
  bindSocketCid,
  postMsgRead,
  checkHasWxnum,
  sendInterview,
  getSysMsgList,
  sysMsgRead,
  getSysSonMsg,
  sonMsgRead,
  delMsg,
  withdrawMsg,
  getRemarkTagList,
  saveRemarks,
  addRemarksTag,
  searchChatList,
} from "@/api/commen/chatApi.js";
import { submitOrder } from "@/api/commen/payApi.js";
import { getSysPush } from "@/api/indiviuser/userInf";
import { updateUserBaseInf } from "@/api/indiviuser/userInf.js";
import { updateCompWxNum } from "@/api/compe/companyInf.js";
import { deliverResume } from "@/api/indiviuser/jobInfoPage.js";
import { formatnyr } from "@/utils/index.js";
import cardindex from "@/components/cardindex.vue";
import cardindex2 from "@/components/cardindex2.vue";
import emoji from "@/components/emoji.vue";
import AESUtil from "@/utils/aes.js";
import choseaddress from "@/components/choseAddressDialog.vue";
import showmapdialog from "@/components/mapShowDialog.vue";
export default {
  name: "chat",
  components: {
    cardindex,
    cardindex2,
    emoji,
    choseaddress,
    showmapdialog,
  },
  computed: {
    noMore() {
      return this.page1 >= this.allpage1;
    },
    disabled() {
      return this.loading;
    },
  },
  data() {
    return {
      uploadUrl: process.env.VUE_APP_UPLOUD + "/api/upload/uploadCos",
      usertype: 1,
      chatList: [
        {
          header_img:
            "https://mp-168c5a18-1927-4a65-8b2c-95087d865588.cdn.bspapp.com/cloudstorage/n4.png",
          name: "系统消息",
          to_id: "0",
          read: 0,
          content: "系统",
        },
      ],
      chatIndex: 0,
      uid: "", // 聊天对象id
      leftinfo: {},
      rightinfo: {},
      msgList: [], // 聊天内容
      chList: [], // 撤回消息
      numInfo: {}, // 我的微信号,手机号
      uNumInfo: {}, // 对方的微信号,手机号
      lastIsdel: 0,
      czIndex: null, // 长按选中消息索引
      emojiMap: {
        "[):]": "ee_1.png",
        "[:D]": "ee_2.png",
        "[;)]": "ee_3.png",
        "[:-o]": "ee_4.png",
        "[:p]": "ee_5.png",
        "[(H)]": "ee_6.png",
        "[:@]": "ee_7.png",
        "[:s]": "ee_8.png",
        "[:$]": "ee_9.png",
        "[:(]": "ee_10.png",
        "[:'(]": "ee_11.png",
        "[<o)]": "ee_12.png",
        "[(a)]": "ee_13.png",
        "[8o|]": "ee_14.png",
        "[8-|]": "ee_15.png",
        "[+o(]": "ee_16.png",
        "[|-)]": "ee_17.png",
        "[:|]": "ee_18.png",
        "[*-)]": "ee_19.png",
        "[:-#]": "ee_20.png",
        "[^o)]": "ee_21.png",
        "[:-*]": "ee_22.png",
        "[8-)]": "ee_23.png",
        "[del]": "btn_del.png",
        "[(|)]": "ee_24.png",
        "[(u)]": "ee_25.png",
        "[(S)]": "ee_26.png",
        "[(*)]": "ee_27.png",
        "[(#)]": "ee_28.png",
        "[(R)]": "ee_29.png",
        "[({)]": "ee_30.png",
        "[(})]": "ee_31.png",
        "[(k)]": "ee_32.png",
        "[(F)]": "ee_33.png",
        "[(W)]": "ee_34.png",
        "[(D)]": "ee_35.png",
      },
      cyyList: [],
      cyyDialogShow: false,
      cyyPopoverShow: false,
      interviewDialogShow: false,
      sendJobDialogShow: false,
      btnsStatus: {},
      sendTextareaText: "",
      jobsrange: {},
      sendJobInf: {},
      chatStr: "",
      usertypearr: false,
      joblist: [],
      interDay: "",
      interStaTime: "",
      interEndTime: "",
      interviewMsgObj: {
        jobid: 0,
      },
      choseAddrDialogShow: false,
      isFistChat: false,
      isHoverId: -1,
      mapShowDialogShow: false,
      bigmapLat: "",
      bigmapLng: "",
      sendBtnDis: true,
      drawerShow: false,
      sonDrawerShow: false,
      systemMsgList: [],
      sysMsgDialogTableVisible: false,
      sysSonMsgList: [],
      msgDetail: "",
      page1: 1,
      allpage1: 0,
      page2: 1,
      allpage2: 0,
      loading: false,
      loading2: false,
      addRemarksDialogTableVisible: false,
      remarkTagInfo: {},
      bzTxt: "",
      isHaveBz: false,
      listSearchKeyWord: "",
      pageIndex: 1,
      listType: 4,
      unreadNum: 0,
      payDialogShow: false,
      zzServiceList: "",
      payDialogTitle: "",
      payType: "wxpay",
      payInf: {},
      wxPayImgUrl: "",
      purchaseType: "0",
    };
  },
  watch: {
    sendTextareaText(newValue, oldValue) {
      console.log(oldValue);
      // 在这里可以执行更多的逻辑
      if (newValue.length > 0) {
        this.sendBtnDis = false;
      } else {
        this.sendBtnDis = true;
      }
    },
  },
  async created() {
    console.log("shi fou is admin:", this.$store.state.user.userinf.ad_login);
    if (this.$store.state.user.userinf.ad_login === "1") {
      this.$message.error("管理员登录不能发送信息");
      this.$router.go(-1);
    } else {
      this.chatList = [
        {
          header_img:
            "https://mp-168c5a18-1927-4a65-8b2c-95087d865588.cdn.bspapp.com/cloudstorage/n4.png",
          name: "系统消息",
          to_id: "0",
          read: 0,
          content: "系统",
        },
      ];
      this.usertype = localStorage.usertype;
      window.addEventListener("onmessageWS", this.getSocketData);
      this.getSysNoticNum();
      if (this.$route.query.chatid) {
        this.uid = this.$route.query.chatid;
        this.getChatUserInfo(this.$route.query.chatid);
        this.getChatChangedInf(this.$route.query.chatid);
        this.getChatHistoryList(this.$route.query.chatid);
        this.getChatList();
      } else {
        this.getChatList();
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("onmessageWS", this.getSocketData);
  },
  methods: {
    /* 切换支付方式 */
    changePayType(type) {
      this.payType = type;
      submitOrder({
        type: this.purchaseType,
        rating_id: this.payInf.detail_id,
        pay_type: this.payType,
      })
        .then((result) => {
          console.log("提交订单", result);
          if (type == "wxpay") {
            this.wxPayImgUrl = result.data.pay_img;
          } else {
            this.aliNatoUrl = result.data.url;
          }
        })
        .catch(() => {});
    },
    toAliPayPage() {
      window.open(this.aliNatoUrl, "_blank");
    },
    /* 添加备注标签 */
    addRemarkTag() {
      this.$prompt("请输入标签内容", "新建标签", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^.{0,8}$/,
        inputErrorMessage: "输入内容不能超过8个字符",
      })
        .then(({ value }) => {
          addRemarksTag({
            to_uid: this.uid,
            label: value,
          })
            .then((result) => {
              console.log("添加标签", result);
              if (result.code == 200) {
                this.$message({
                  type: "success",
                  message: "添加成功",
                });
                var arr = this.remarkTagInfo;
                arr.label_list.push({
                  label: value,
                  is_remark: 0,
                });
                // this.getRemarksTag(value);
                arr.label_list.forEach((it) => {
                  if (it.label == value) {
                    it.ischeck = true;
                    arr.label = it.label;
                  } else {
                    it.ischeck = false;
                  }
                });
                this.$nextTick(() => {
                  this.remarkTagInfo = arr;
                });
              }
            })
            .catch(() => {});
          //
        })
        .catch(() => {});
    },
    /* 保存备注 */
    saveRemarks() {
      saveRemarks({
        text: this.bzTxt,
        to_uid: this.uid,
        label: this.remarkTagInfo.label,
      })
        .then((result) => {
          console.log("保存备注", this.remarkTagInfo.label);
          this.$message({
            message: result.msg,
            type: "success",
          });
          if (result.code == 200) {
            this.addRemarksDialogTableVisible = false;
            this.getChatList();
            this.chatList[this.chatIndex].label = this.remarkTagInfo.label;
          }
        })
        .catch(() => {});
    },

    choseRemarTag(e) {
      var lis = this.remarkTagInfo.label_list;
      lis.forEach((i) => {
        if (i.label == e) {
          i.ischeck = true;
          this.remarkTagInfo.label = i.label;
        } else {
          i.ischeck = false;
        }
      });
    },
    /* 添加备注 */
    addRemarks() {
      this.getRemarksTag();
      this.addRemarksDialogTableVisible = true;
    },
    /* 获取标签 */
    getRemarksTag() {
      getRemarkTagList({
        to_uid: this.uid,
      })
        .then((result) => {
          console.log("获取备注标签", result);
          result.data.label_list.forEach((it) => {
            if (it.is_remark == 1) {
              it.ischeck = true;
            } else {
              it.ischeck = false;
            }
          });
          this.remarkTagInfo = result.data;
          this.bzTxt = result.data.text;
        })
        .catch(() => {});
    },

    /* 复制消息 */
    async addforCopy(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.$message({
          message: "复制成功",
          type: "success",
        });
      } catch (err) {
        console.error("复制失败", err);
        this.$message({
          message: "复制失败",
          type: "warning",
        });
      }
    },
    /* 添加常用语 */
    addforCyy(e) {
      addChatCyyList({ content: e })
        .then((result) => {
          console.log("添加常用语", result);
          this.$message({
            message: "添加成功！",
            type: "success",
          });

          this.getChatCyyList();
        })
        .catch(() => {});
    },
    /* 撤回消息 */
    withdrawMsg(id, index) {
      var that = this;
      withdrawMsg({
        id: id,
        to_uid: that.uid,
      })
        .then((result) => {
          console.log("撤回消息", result);
          if (result.code == 200) {
            that.$set(that.msgList[index], "isYc", true);
          } else {
            that.$message.error(result.msg);
          }
        })
        .catch(() => {});
    },

    // 长按聊天记录显示操作
    bindLongmsg(e, index) {
      let to = e.to;
      if (to == this.uid) {
        this.czIndex = index;
        this.czMsgId = e.id;
      } else {
        this.czMsgId = null;
        this.czIndex = null;
      }
    },

    toInerviewPage(id) {
      this.$router.push(`/jobInformation?type=2&invid=${id}`);
    },
    /* 复制到剪切板 */
    onError() {
      console.error("复制失败");
    },
    // 复制
    onCopy() {
      this.$message.success("复制成功");
    },
    chatListItemMouseOver(id) {
      this.isHoverId = id;
    },
    chatListItemMoseOut() {
      this.isHoverId = -1;
    },

    bigMapShowFun(lat, lng) {
      this.bigmapLat = lat;
      this.bigmapLng = lng;
      this.mapShowDialogShow = true;
    },
    /* 获取系统消息角标 */
    getSysNoticNum() {
      getSysPush({
        from: "pc",
      })
        .then((result) => {
          this.chatList.forEach((itt) => {
            if (itt.to_id == 0) {
              itt.read = parseInt(result.data.notice_all);
              itt.content = result.data.content;
            }
          });
        })
        .catch(() => {});
    },

    changeChatType(type) {
      this.chatList = [
        {
          header_img:
            "https://mp-168c5a18-1927-4a65-8b2c-95087d865588.cdn.bspapp.com/cloudstorage/n4.png",
          name: "系统消息",
          to_id: "0",
          read: 0,
          content: "系统",
        },
      ];
      this.listSearchKeyWord = "";
      this.listType = type;
      this.getChatList();
    },

    toSearchList() {
      if (this.listSearchKeyWord) {
        this.chatList = [];
        searchChatList({
          keyword: this.listSearchKeyWord, // 搜索文字
        })
          .then((result) => {
            console.log("搜索", result);
            var li = {
              header_img: "",
              name: "",
              to_id: "0",
              read: 0,
              content: "",
            };
            var larr = [];
            result.data.forEach((elit) => {
              li.header_img = elit.photo;
              li.name = elit.ftName;
              li.to_id = elit.peer_uid;
              li.time = elit.sendTime;
              li.content = elit.content;
              larr.push(li);
            });
            this.chatList = larr;
          })
          .catch(() => {});
      } else {
        this.chatList = [
          {
            header_img:
              "https://mp-168c5a18-1927-4a65-8b2c-95087d865588.cdn.bspapp.com/cloudstorage/n4.png",
            name: "系统消息",
            to_id: "0",
            read: 0,
            content: "系统",
          },
        ];
        this.getChatList();
      }
    },

    getChatList() {
      getChatList({
        keyword: "", // 搜索文字
        sign: "", // 牛人标记
        job1: "", // 一级职位
        job1_son: "", // 二级职位
        child: "",
        page: this.pageIndex,
        type: this.listType,
      })
        .then((result) => {
          console.log(result);
          this.unreadNum = result.data.unread_num;
          var msglist = this.chatList;
          // msglist = result.data.list.concat(msglist);
          msglist = msglist.concat(result.data.list);
          // result.data.list

          var ls = msglist.reduce((acc, current) => {
            const x = acc.find((item) => item.to_id === current.to_id);
            if (!x) {
              acc.push(current);
            }
            return acc;
          }, []);
          this.$nextTick(() => {
            this.chatList = ls;
          });
          // if (!this.$route.query.chatid) {
          //   var data = result.data.list[0];
          //   var id = data["to_id"];
          //   console.log("00===000000=====00");
          //   this.uid = id;
          //   this.getChatUserInfo(id);
          //   this.getChatChangedInf(id);
          //   this.getChatHistoryList(id);
          // }
        })
        .catch(() => {});
    },

    changeChatUser(item, index) {
      if (item.to_id == 0) {
        this.uid = 0;

        // alert("000");
        this.getSysMsgList();
      } else {
        this.uid = item.to_id;
        this.chatIndex = index;
        if (item.label) {
          this.isHaveBz = true;
        } else {
          this.isHaveBz = false;
        }
        this.getChatUserInfo(item.to_id);
        this.getChatChangedInf(item.to_id);
        this.getChatHistoryList(item.to_id);
      }
    },

    /* 获取系统消息列表 */
    getSysMsgList() {
      getSysMsgList({
        page: this.page1,
      })
        .then((result) => {
          this.allpage1 = result.data.allpage;
          this.drawerShow = true;
          this.loading = false;
          if (this.page1 == 1) {
            this.systemMsgList = result.data.list;
          } else {
            this.systemMsgList = this.systemMsgList.concat(result.data.list);
          }
        })
        .catch(() => {});
    },

    load() {
      this.loading = true;
      if (this.page1 < this.allpage1) {
        this.page1 = this.page1 + 1;
        this.getSysMsgList();
      }
    },

    /* 删除消息 */
    delMsg(type, id) {
      delMsg({
        type: type,
        id: id,
      })
        .then((result) => {
          this.$message({
            message: result.msg,
            type: "success",
          });
          if (type == 1) {
            this.sysSonMsgList = [];
            this.getSysSonMsg();
          } else {
            this.systemMsgList = [];
            this.getSysMsgList();
          }
          this.getnotice();
          this.getSysNoticNum();
          this.$store.dispatch("user/setWdMsgNumber");
        })
        .catch(() => {});
    },
    //获取聊天信息
    getChatUserInfo(toid) {
      getChatUserInfo({ to_uid: toid })
        .then((result) => {
          // console.log("获取聊天信息", result);
          this.numInfo = {
            wxnum: result.data.wxnum,
            moblie: result.data.tel,
          };
          let personinfo = {
            chatavatar: result.data.resu_avatar,
            name: result.data.resu_name,
            reseume_photo: result.data.resu_avatar,
            maxsalary: result.data.max_salary,
            minsalary: result.data.min_salary,
            yearsold: result.data.age,
            eduname: result.data.edu,
            exp_name: result.data.exp,
            // city_name: res.data.job_class_name,
            uid: result.data.uid,
            city_name: result.data.city_class_name.split(","),
            job_class: result.data.job_class_name.split(","),
          };
          let jobinfo = {
            chatavatar: result.data.com_logo,
            name: result.data.com_name,
            logo: result.data.com_logo,
            num: result.data.com_job_num,
            province: result.data.com_province,
            job: result.data.com_job_name,
            uid: result.data.to_uid,
            jobs: result.data.jobs,
            com_hy: result.data.com_hy,
          };

          if (this.usertype == 1) {
            // jobinfo.
            this.leftinfo = jobinfo;
            this.rightinfo = personinfo;
          } else {
            personinfo.zuid = jobinfo.uid;
            jobinfo.zuid = personinfo.uid;
            this.leftinfo = personinfo;
            this.rightinfo = jobinfo;
            // console.log(res.data.uid);
            // console.log(res.data.to_uid);
            // console.log(this.leftinfo.name, this.leftinfo.zuid);
            // console.log(this.rightinfo.name, this.rightinfo.zuid);
          }
          postMsgRead({
            from_id: this.uid,
          })
            .then(() => {
              // console.log("消息状态", result);
              this.chatList.forEach((it) => {
                if (it.to_id == this.uid) {
                  it.read = 0;
                }
              });
              this.$store.dispatch("user/setWdMsgNumber");
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    closeDrawer() {
      this.drawerShow = false;
    },

    toDetail(item) {
      sysMsgRead({
        id: item.id,
      })
        .then(() => {
          if (item.title === "系统通知") {
            this.sysSonMsgList = [];
            this.getSysSonMsg();
            this.getSysNoticNum();
            this.$store.dispatch("user/setWdMsgNumber");
            this.drawerShow = false;
            this.sonDrawerShow = true;
            return false;
          }
          if (this.usertype == 1) {
            if (item.type == 2) {
              this.$router.push("/userhomepage");
            } else {
              this.systemMsgList = [];
              this.getSysMsgList();
              this.getSysNoticNum();
              this.$store.dispatch("user/setWdMsgNumber");
            }
          } else {
            if (item.type == 4) {
              if (item.jobid != 0) {
                this.$router.push(`/jobinfopage?id=${item.id}&isyl=1`);
              }
            } else if (item.type == 7) {
              this.$router.push(`/userhomepage`);
            } else {
              this.msgDetail = item.desc;
              this.sysMsgDialogTableVisible = true;
              this.systemMsgList = [];
              this.getSysMsgList();
              this.getSysNoticNum();
              this.$store.dispatch("user/setWdMsgNumber");
            }
          }
        })
        .catch(() => {});
    },
    sonClickFun(item) {
      sonMsgRead({
        id: item.id,
      })
        .then(() => {
          if (item.link_status == 1) {
            // let url = '/pagesA/user/resume/detail?uid=' + uid;
            if (this.usertype == 1) {
              // 	url = '/pages/index/cominfo?id=' + uid;
              this.$router.push(`/companyinf?id=${item.link_id}`);
            } else {
              this.$router.push(`/talentsdetail?id=${item.link_id}`);
            }
            // uni.navigateTo({
            // 	url: url
            // });
          } else if (item.link_status == 2) {
            this.$message({
              message: "该求职信息还未通过审核，暂不支持查看！",
              type: "warning",
            });
          } else if (item.link_status == 3) {
            if (this.usertype == 1) {
              this.$message({
                message: "该公司信息已删除不能查看了！",
                type: "warning",
              });
            } else {
              this.$message({
                message: "该求职信息已删除不能查看了！",
                type: "warning",
              });
              // uni.showToast({
              // 	title: '该求职信息已删除不能查看了！',
              // 	icon: 'none'
              // });
            }
          }
        })
        .catch(() => {});
    },
    /* 系统消息点进去的列表 */
    getSysSonMsg() {
      getSysSonMsg({ page: this.page2 })
        .then((result) => {
          this.loading = false;
          this.allpage2 = result.data.allpage;
          if (this.page2 == 1) {
            this.sysSonMsgList = result.data.list;
          } else {
            this.sysSonMsgList = this.sysSonMsgList.concat(result.data.list);
          }
          // this.sysSonMsgList = result.data.list;
        })
        .catch(() => {});
    },

    load2() {
      this.loading = true;

      if (this.page2 < this.allpage2) {
        this.page2 = this.page2 + 1;
        this.getSysSonMsg();
      }
    },

    //获取交换信息状态
    getChatChangedInf(toid) {
      getChatChangedInf({ to_uid: toid })
        .then((result) => {
          this.btnsStatus = result.data.btns;
        })
        .catch(() => {});
    },

    /* 监听聊天消息 */
    getSocketData(res) {
      var msgData = res.detail.data;
      if (msgData.type == "initialization") {
        bindSocketCid({
          cid: msgData.client_id,
          pageurl: "login",
        })
          .then(() => {})
          .catch(() => {});
      } else {
        if (msgData.from == this.uid) {
          postMsgRead({
            from_id: msgData.from,
          })
            .then((result) => {
              console.log("消息状态", result);
            })
            .catch(() => {});
        }
        if (msgData.sourceType == "text" || msgData.sourceType == "badgeNum") {
          this.chatList = [
            {
              header_img:
                "https://mp-168c5a18-1927-4a65-8b2c-95087d865588.cdn.bspapp.com/cloudstorage/n4.png",
              name: "系统消息",
              to_id: "0",
              read: 0,

              content: "系统",
            },
          ];
          this.getChatList();
          this.$store.dispatch("user/setWdMsgNumber");
        }
        if (msgData.sourceType == "read") {
          if (this.uid != 0 && this.uid) {
            this.getChatHistoryList(this.uid);
          }
        } else {
          if (msgData.from == this.uid || msgData.sourceType == "own") {
            // var data = {
            //   to_uid: this.uid,
            //   id: this.lastChatId, // 最后一条信息的信息id
            //   is_del: this.lastIsdel, //最后一条是否已删除
            // };
            // console.log('监听消息', res);
            let list = this.msgList;
            // if (msgData.length > 0) {
            let chid = 0;
            // let msgdata = res;
            // res.data.forEach((item, index) => {
            let msgdata = msgData;
            if (msgData.is_del == "1") {
              msgData.isYc = true;
              this.lastIsdel = 1;
              chid = msgData.id;
            } else {
              this.lastIsdel = 0;
            }
            list.forEach((it, id) => {
              if (it.id == chid && it.is_del == "0") {
                list.splice(id, 1);
              }
            });
            //缓存中有相同的话，就不存了
            if (msgData.type == 4) {
              this.getChatWxnoPhnoInfo();
            }
            if (msgData.type == 4 || msgData.type == 4) {
              // this.getChatBtnStatus();
            }
            list.push(msgdata);
            // });
            list = this.getdata(list, this.msgList);
            // console.log(res.data.length - 1);
            // console.log(this.getdata(list, that.msgList));
            this.msgList = list;
            this.lastChatId = msgData.id;

            this.setScroll();

            // }
            // if (res.append.status == 1) {
            list = this.getdata(list, this.msgList);
            // list.forEach(iii => {
            // 	iii.status = 1
            // })
            this.msgList = list;
            this.decomposeMsg();
            // if (list.length > 0) {
            // 	uni.setStorageSync(`userLt_${that.uid}`, list);
            // }
            // }
          }
        }
      }
    },

    //获取聊天历史记录
    getChatHistoryList(toid) {
      getChatHistoryList({ to_uid: toid, page: 1 })
        .then((result) => {
          console.log("获取历史记录", result);
          if (result.code == 200) {
            // uni.hideLoading();
            let count = 0;
            let list = [];
            if (result.data.length > 0) {
              // console.log(that.msgList);
              // this.lastChatId = res.data[res.data.length - 1].id;
              let chid = 0;
              result.data.forEach((item) => {
                if (item.is_del == "1") {
                  item.isYc = true;
                  this.lastIsdel = 1;
                  chid = item.id;
                } else {
                  this.lastIsdel = 0;
                }
                if (item.id == chid && item.is_del == "0") {
                  list.splice(item.id, 1);
                }
              });
              this.msgList = result.data;
              this.decomposeMsg();
            } else {
              // 	// 拿缓存聊天数据
              list = list ? list : [];
              this.msgList = list;
              // that.lastChatId = list[res.data.length - 1].id;
              list.forEach((item) => {
                if (item.ext.job == true) {
                  count = 1;
                }
              });
              // 求职端从未发过消息
              if (
                (list.length == 0 || count == 0) &&
                this.usertype == 1 &&
                this.job_id
              ) {
                console.log("1");
              } else {
                if (this.usertype == 2 && this.istoRe) {
                  this.cardShow = true;
                }
              }
              if (list.length == 0) {
                // console.log('没发过');
                this.getNewMsg();
              }
              this.setScroll();
            }
          }
        })
        .catch(() => {});
    },

    // 分解聊天记录
    decomposeMsg() {
      let msgList = this.msgList;
      msgList.forEach((item, index) => {
        msgList[index].timeStr = this.getTimes(item.time);
        if (item.type == 1) {
          msgList[index].dataList = this.textMsg(item.content);
        } else if (item.type == 2) {
          msgList[index].ext = JSON.parse(item.content);
        } else if (item.type == 3 || item.type == 4 || item.type == 9) {
          // console.log("ooo", JSON.parse(item.content));

          if (item.type_agree == 1) {
            this.getChatWxnoPhnoInfo();
          }
        } else if (item.type == 5) {
          msgList[index].ext = JSON.parse(item.content);
          // console.log('格式换', JSON.parse(item.content));
        } else if (item.type == 6) {
          msgList[index].url = JSON.parse(item.content).imageUrl;
        } else if (item.type == 7) {
          // console.log(item);
          if (JSON.parse(item.content).mtype == "[视频]") {
            msgList[index].videoUrl = JSON.parse(item.content).videoUrl;
            msgList[index].coverUrl = JSON.parse(item.content).coverUrl;
            msgList[index].mtype = JSON.parse(item.content).mtype;
          } else {
            msgList[index].url = JSON.parse(item.content).aduUrl;
            msgList[index].long = JSON.parse(item.content).long;
          }
        } else if (item.type == 8) {
          // item.content.
          let locaInf = JSON.parse(item.content);
          locaInf.latitude = parseFloat(locaInf.latitude);
          locaInf.longitude = parseFloat(locaInf.longitude);
          let mark = [
            {
              iconPath: "@/assets/address_manr_mark.webp",
              id: 0,
              latitude: locaInf.latitude,
              longitude: locaInf.longitude,
              width: "361",
              height: "147",
              callout: {
                display: "ALWAYS",
                borderRadius: 6,
                color: "#757575",
                content: locaInf.address,
                bgColor: "#FFFFFF",
                padding: 10,
              },
            },
          ];
          msgList[index].mark = mark;
        } else if (item.type == 11) {
          // this.getNumInfos();
        }
      });

      this.msgList = msgList;
      // console.log("历史记录", this.msgList);

      this.setScroll();
    },

    /* 去重操作 */
    getdata(a1, a2) {
      var aa1 = a1;
      var aa2 = a2;
      for (let i = 0, len = aa2.length; i < len; i++) {
        let flag = true;
        for (let j = 0; j < aa1.length; j++) {
          if (aa2[i].id === aa1[j].id) {
            flag = false;
            break;
          }
        }
        if (flag) {
          aa1 = aa1.concat(aa1.push(aa2[i]));
        }
      }
      return a1;
    },
    /* 首次聊天打招呼 */
    getNewMsg() {
      var type = 0;
      if (this.usertype == 1) {
        type = 5;
      } else {
        type = 6;
      }
      fistChatToMsg({
        job_id: "",
        type: type,
      })
        .then((result) => {
          console.log("首次聊天", result);
          this.sedChatMsg(result.data.content, "", 1);

          this.isFistChat = true;
          setTimeout(() => {
            this.chatList = [
              {
                header_img:
                  "https://mp-168c5a18-1927-4a65-8b2c-95087d865588.cdn.bspapp.com/cloudstorage/n4.png",
                name: "系统消息",
                to_id: "0",
                read: 0,
                content: "系统",
              },
            ];

            this.getChatList();
          }, 1000);
        })
        .catch(() => {});
    },

    /* 获取对方发手机号和微信号 */
    getChatWxnoPhnoInfo() {
      getChatWxnoPhnoInfo({
        uid: this.uid,
        per_uid: this.rightinfo.uid,
        per_name: this.rightinfo.name,
        com_alias: this.leftinfo.name,
        type: 2,
      })
        .then((result) => {
          // console.log("对方微信和手机号", result);
          result.data.phonejm = AESUtil.decrypt(
            result.data.phone,
            "8sdowpgidmsuepj9",
            "inuhvgtflsozmehg"
          );
          result.data.wxnumjm = AESUtil.decrypt(
            result.data.wxnum,
            "8sdowpgidmsuepj9",
            "inuhvgtflsozmehg"
          );
          console.log("对方微信和手机号", result);
          this.uNumInfo = result.data;
        })
        .catch(() => {});
    },

    /* 改变交换的状态 */
    changeWxPhStatus(e) {
      // console.log('有没有微信', e);
      if (e.status == 4) {
        this.getChatWxnoPhnoInfo();
      }
      if (!this.numInfo.wxnum && e.type == "wxnum") {
        // console.log('你没有微信', this.numInfo.wxnum);
        if (e.status != 3) {
          this.noWxTx = true;
        } else {
          console.log();
        }
        // uni.showToast({
        // 	title: '您还没有绑定微信,无法完成分享',
        // 	icon: 'none'
        // });
        return;
      }
      if (e.type != "moblie" && !this.numInfo.wxnum) {
        this.operationType = 8;
        this.statusInfos = e;
        return;
      }
      changeWxPhStatus({
        to_uid: this.uid,
        type: e.type,
        status: e.status == 4 ? 2 : e.status,
      })
        .then((result) => {
          console.log("改变按钮状态", result);
          // var data = {
          //   to_uid: this.uid,
          //   type: e.type,
          //   status: e.status == 4 ? 2 : e.status,
          // };
          if (result.code == 0) {
            this.$message.error(result.msg);
            // uni.showToast({
            //   title: result.msg,
            //   icon: "none",
            // });
            return;
          }

          if (result.code == 200) {
            let str = "";
            let data = {};
            if (e.status == 1) {
              //发送电话 微信申请
              str = e.type == "moblie" ? "[交换手机号]" : "[交换微信]";
              if (e.type == "moblie") {
                data.ExchangePhone = true;
              } else {
                data.ExchangeWeChat = true;
              }
            } else if (e.status == 2) {
              //同意
              str = e.type == "moblie" ? "[电话]" : "[微信]";
              if (e.type == "moblie") {
                data.phone_card = true;
                data.phone = this.numInfo.moblie;
              } else {
                data.cards = true;
                data.weixin = this.numInfo.wxnum;
              }
              this.sendNumsInfo(str, data);
              return;
            } else if (e.status == 3) {
              str = e.type == "moblie" ? "已拒绝交换电话" : "已拒绝交换微信";
              if (e.type == "moblie") {
                data.RefuseExchangePhone = true;
              } else {
                data.RefuseExchangeWeChat = true;
              }
            }
            // this.sendtxtMsg(str, data, 1);
            // this.getChatBtnStatus();
          }
        })
        .catch(() => {});
    },

    lookInfos() {},
    // 解时间
    getTimes(e) {
      // let date = new Date(e * 1),
      let str = formatnyr(e);
      return str.md + " " + str.hm;
    },
    // 文本 表情数据
    textMsg(e) {
      let list = [];
      let str = e.split("[");
      str.forEach((item) => {
        let data = {};
        if (item.indexOf("]") != -1) {
          let newStr = item.split("]");
          let maps = this.emojiMap;
          // console.log("包含", maps);
          newStr.forEach((strItem) => {
            let count = 0;
            for (var key in maps) {
              if (key == "[" + strItem + "]") {
                data.msg = require("@/assets/faces/" + maps[key]);
                count = 1;
              }
            }
            if (count == 1) {
              // 表情
              data.type = "emoji";
            } else {
              data.type = "txt";
              data.msg = strItem;
            }
            list.push({
              ...data,
            });
          });
        } else {
          // console.log('不包含');
          data.type = "txt";
          data.msg = item;
          list.push(data);
          // console.log(data,item);
        }
      });
      // console.log(list);
      return list;
    },

    changeWxNumber(sts) {
      console.log(sts);
      if (this.usertype == 1) {
        this.$confirm("确认与对方交换微信吗？", "提醒", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            // if (this.usertype == 1) {
            checkWxNumber({ to_uid: this.uid })
              .then((result) => {
                console.log("检测微信号", result);
                if (result.data.to_wxnum == "") {
                  this.$confirm(
                    "对方没有绑定微信信息，电话联系TA吧！",
                    "提示",
                    {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "warning",
                    }
                  )
                    .then(() => {})
                    .catch(() => {});
                } else {
                  this.sendZDYmsg({
                    type: "wxnum",
                    status: this.btnsStatus.wxnum,
                    isqq: true,
                  });
                }
              })
              .catch(() => {});
            // } else {
            //   this.sendZDYmsg({
            //     type: "wxnum",
            //     status: this.btnsStatus.wxnum,
            //     isqq: true,
            //   });
            //   // console.log('查看传的状态', this.btnsStatus.wxnum);
            //   this.changeWxPhStatus({
            //     type: "wxnum",
            //     status: this.btnsStatus.wxnum,
            //   });
            // }
          })
          .catch(() => {});
      } else {
        if (sts == 1) {
          this.$confirm("确认与对方交换微信吗？", "提醒", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.sendZDYmsg({
                type: "wxnum",
                status: this.btnsStatus.wxnum,
                isqq: true,
              });
              // console.log('查看传的状态', this.btnsStatus.wxnum);
              this.changeWxPhStatus({
                type: "wxnum",
                status: this.btnsStatus.wxnum,
              });
              // }
            })
            .catch(() => {});
        } else if (sts == 2) {
          this.$message({
            message: "请求已发送，待对方同意",
            type: "warning",
          });
          return;
        } else if (sts == 3) {
          var msg = {
            from: this.rightinfo.uid,
            to: this.leftinfo.uid,
            type: "4",
            type_agree: "1",
            content: "[交换微信]",
            time: this.msgList[this.msgList.length - 1].time,
            timeStr: this.msgList[this.msgList.length - 1].timeStr,
          };
          // this.btnsStatus.wxnum = 3;
          this.msgList.push(msg);
          // uni.showToast({
          // 	title: '对方已同意',
          // 	icon: 'none'
          // });
          // let data = {}
          // data.cards = true;
          // data.weixin = this.numInfo.wxnum;
          // this.sendNumsInfo('[交换微信]', data);
          return;
        } else if (sts == 4) {
          this.$message({
            message: "对方拒绝交换微信",
            type: "warning",
          });
          return;
        }
      }
    },

    /* 同意或拒绝 */
    changeNums(idd, stat, typ) {
      let type = "";
      var id = idd;
      this.usertypearr = true;
      if (typ == "wxnum" && stat == "1") {
        console.log("点击了拒绝", stat);
        var chuid = 0;
        if (this.usertype == 1) {
          chuid = this.leftinfo.uid;
        } else {
          chuid = this.rightinfo.uid;
        }
        console.log(chuid);
        /* 检查有没有绑定微信 */
        checkHasWxnum({
          uid: chuid,
          per_uid: this.rightinfo.uid,
          per_name: this.rightinfo.name,
          // com_uid: this.leftinfo.uid,
          com_alias: this.leftinfo.name,
          type: 1,
        })
          .then((result) => {
            console.log("检查微信", result);
            if (result.data.wxnum == "") {
              this.$prompt("请输入你的微信号", "请先填写你的微信号", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
              })
                .then(({ value }) => {
                  if (this.usertype == 1) {
                    this.updateUserBaseInf(value);
                  } else {
                    this.updateCompWxNum(value);
                  }
                })
                .catch(() => {});
              return false;
            }
          })
          .catch(() => {});
      }

      // if (typ) {
      type = typ;
      // } else {
      //   type = this.operationType == 2 ? "moblie" : "wxnum";
      // }
      let status = stat;

      var ls = this.msgList;
      ls.forEach((it, index) => {
        if (it.id == id) {
          it.agreestutas = true;
          this.$set(this.msgList[index], it);
        }
      });
      this.msgList = ls;
      // uni.setStorageSync(`userLt_${that.uid}`, ls);
      // console.log('thaaaaa', that.msgList);
      // 发送消息
      // if (that.usertype == 2) {
      // console.log('查看传的状态', parseInt(status) + 1);
      this.changeWxPhStatus({
        type: type,
        status: parseInt(status) + 1,
      });

      this.sendZDYmsg({
        type: type,
        status: status,
        isqq: false,
      });
    },

    /* 求职者添加微信号 */
    updateUserBaseInf(value) {
      updateUserBaseInf({
        wxnum: value,
      })
        .then((result) => {
          console.log("usertype1提交微信号", result);
          if (result.code == 200) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.numInfo.wxnum = result.data.userinfo.wxnum;
            if (this.usertypearr) {
              this.sendZDYmsg({
                type: "wxnum",
                status: 1,
                isqq: false,
              });
              this.changeWxPhStatus({
                type: "wxnum",
                status: 2,
              });
            } else {
              this.sendZDYmsg({
                type: "wxnum",
                status: 1,
                isqq: true,
              });
            }
          }
        })
        .catch(() => {});
    },
    /* 企业添加微信号 */
    updateCompWxNum(value) {
      updateCompWxNum({
        wxnum: value,
      })
        .then((result) => {
          console.log("企业添加微信号", result);
          if (result.code == 200) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
          }
        })
        .catch(() => {});
    },

    changePhoneNumber(sts) {
      if (this.usertype == 1) {
        // this.modelcontent = "确认与对方交换电话吗";
        // this.showmodel = true;
        this.$confirm("确认与对方交换电话吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.sendZDYmsg({
              type: "moblie",
              status: this.btnsStatus.moblie,
              isqq: true,
            });
            this.getChatWxnoPhnoInfo();
            // console.log('查看传的状态', this.btnsStatus.wxnum);
            // this.changeWxPhStatus({
            //   type: "moblie",
            //   status: this.btnsStatus.moblie,
            // });
          })
          .catch(() => {});
      } else {
        if (sts == 1) {
          this.$confirm("确认与对方交换电话吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.sendZDYmsg({
                type: "moblie",
                status: this.btnsStatus.moblie,
                isqq: true,
              });
              this.changeWxPhStatus({
                type: "moblie",
                status: this.btnsStatus.moblie,
              });
            })
            .catch(() => {});
        } else if (sts == 2) {
          this.$message({
            message: "请求已发送，待对方同意",
            type: "warning",
          });
          return;
        } else if (sts == 3) {
          var msg = {
            from: this.rightinfo.uid,
            to: this.leftinfo.uid,
            type: "3",
            type_agree: "1",
            content: "[交换手机号]",
            time: this.msgList[this.msgList.length - 1].time,
            timeStr: this.msgList[this.msgList.length - 1].timeStr,
          };
          // this.btnsStatus.moblie = 3;
          this.msgList.push(msg);
          return;
        } else if (sts == 4) {
          this.$message({
            message: "对方拒绝交换电话",
            type: "warning",
          });
          return;
        }
      }
    },

    /* 回车发送消息 */
    enterSendFun() {
      // console.log(res);
      let e = window.event || arguments[0];
      // console.log(e,e.keyCode)
      if (e.key == "Enter" || e.code == "Enter" || e.keyCode == 13) {
        e.returnValue = false;
        // this.changeSendOut();
        this.sedChatMsg(this.sendTextareaText, "", 1);
        this.sendTextareaText = "";
        return false;
      }
    },
    /* 按钮发送消息 */
    btnSendMsg() {
      this.sedChatMsg(this.sendTextareaText, "", 1);
      this.sendTextareaText = "";
    },
    /* 发送常用语 */
    sendCyyMsyFun(item) {
      this.cyyPopoverShow = false;
      this.sedChatMsg(item.content, "", 1);
    },

    /* 发送消息 */

    sedChatMsg(e, extData, type) {
      if (type != 6) {
        // that.fsStatus = true;
      }

      let msgstr = e;
      var new_content = "";
      // let id = conn.getUniqueId();
      // let msg = new WebIM.message('txt', id); // 创建文本消息
      let ext = {};
      let agree = 0;

      if (extData) {
        ext = extData;
      }
      ext.type = this.usertype;
      ext.usertype = this.usertype;
      if (type == 1) {
        this.msgFocus = true;
        new_content = msgstr;
      }
      if (type == 2) {
        new_content = {
          id: extData.id,
          job: extData.job,
          jobcontent: extData.jobcontent,
        };
        new_content = JSON.stringify(new_content);
      }
      if (type == 5) {
        if (extData.send_interview) {
          new_content = {
            job_id: this.job_id ? this.job_id : "",
            interview_id: extData.interview_id,
            send_interview: extData.send_interview,
            usertype: this.usertype,
          };
        } else {
          new_content = {
            job_id: this.job_id ? this.job_id : "",
            interview_id: extData.interview_id,
            agree_interview: extData.agree_interview,
            usertype: this.usertype,
          };
        }
        // interview_id: this.interview_id,
        // refuse_interview: true,
        // agree: 2,
        // interview_id: this.interview_id,
        // agree_interview: true,
        // agree: 1
        agree = extData.agree;
        new_content = JSON.stringify(new_content);
      }
      if (type == 6) {
        new_content = {
          imageUrl: extData.url,
        };
        new_content = JSON.stringify(new_content);
      }
      if (type == 7) {
        if (msgstr == "[语音]") {
          new_content = {
            aduUrl: extData.uri + "/" + extData.entities[0].uuid,
            long: extData.duration,
          };
        } else {
          var cover = extData.data.cover.replace(
            "./data",
            `${process.env.VUE_APP_IMG}/data`
          );
          new_content = {
            videoUrl: process.env.VUE_APP_IMG + extData.data.url,
            coverUrl: cover,
            mtype: msgstr,
            // long: extData.duration
          };
        }

        new_content = JSON.stringify(new_content);
      }
      if (type == 8) {
        new_content = {
          address: extData.address,
          longitude: extData.longitude,
          latitude: extData.latitude,
          name: extData.name,
        };
        new_content = JSON.stringify(new_content);
      }
      sedChatMsg({
        type: type,
        content: msgstr,
        to_uid: this.uid,
        chat_type: "normal",
        agree: agree,
        job_id: this.job_id ? this.job_id : "",
        new_content: new_content,
      })
        .then((result) => {
          console.log("发送消息", result);
          if (result.code == 200) {
            if (this.isFistChat) {
              if (this.$route.query.chatid) {
                this.$nextTick(function () {
                  this.uid = this.$route.query.chatid;
                });
                this.getChatUserInfo(this.uid);
                this.getChatChangedInf(this.uid);
                this.getChatHistoryList(this.uid);
              }
            }

            this.msgList.push(result.data[0]);
            this.decomposeMsg();
            this.setScroll();
          } else if (result.code == 201) {
            this.$confirm(
              "您当前账号暂不支持回复消息 ，请升级VIP会员或联系客服",
              "温馨提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.$router.push(`/vippaypage`);
              })
              .catch(() => {});
          } else if (result.code == 202) {
            this.$confirm(result.msg, "未购买开放该职位", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push(`/vippaypage`);
              })
              .catch(() => {});
          } else if (result.code == 203) {
            this.$confirm(
              "请升级VIP会员或购买相对应的职位开放权限，才可以联系TA 。",
              "请升级VIP会员",
              {
                confirmButtonText: "立即升级",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.$router.push(`/vippaypage`);
              })
              .catch(() => {});
          } else if (result.code == 204) {
            this.$confirm(
              "您今天的直聊权益已用完，购买当日畅聊权益，今日不限聊！",
              "当日畅聊权益",
              {
                confirmButtonText: "立即购买",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.payInf = {
                  name: "当日畅聊卡",
                  use: "当日",
                  service_price: "6",
                  detail_id: 0,
                };
                this.payDialogTitle = "当日畅聊";
                this.purchaseType = "33";
                this.payDialogShowFun();
              })
              .catch(() => {});
          }else if (result.code === 206){
            this.$confirm(
                "免费会员不能回复超过24小时的信息！",
                "请升级VIP会员",
                {
                  confirmButtonText: "立即升级",
                  cancelButtonText: "取消",
                  type: "warning",
                }
            )
                .then(() => {
                  this.$router.push(`/vippaypage`);
                })
                .catch(() => {});
          }
        })
        .catch(() => {});
    },

    payDialogShowFun() {
      submitOrder({
        type: this.purchaseType,
        rating_id: this.payInf.detail_id,
        pay_type: this.payType,
      })
        .then((result) => {
          console.log("提交订单", result);
          this.wxPayImgUrl = result.data.pay_img;
          this.payDialogShow = true;
        })
        .catch(() => {});
    },

    /* 交换微信号手机号  1申请 2同意 3拒绝 */
    // 交换微信号手机号 // 1申请 2同意 3拒绝
    sendZDYmsg(e) {
      console.log(e);
      let content = "",
        type = 0,
        new_content = "",
        agree = 0;
      if (e.type == "moblie") {
        content =
          e.status == 0
            ? "[交换手机号]"
            : e.status == 1
            ? "[电话]"
            : "已拒绝交换手机号";
        new_content =
          e.status == 0
            ? "[交换手机号]"
            : e.status == 1
            ? "[电话]"
            : "已拒绝交换手机号";
        if (this.usertype == 1) {
          type = 3;
          if (e.isqq) {
            // agree = 1;
            var msg = {
              from: this.leftinfo.uid,
              to: this.rightinfo.uid,
              type: "3",
              type_agree: "1",
              content: "[交换手机号]",
              time: this.msgList[this.msgList.length - 1].time,
              timeStr: this.msgList[this.msgList.length - 1].timeStr,
            };
            this.btnsStatus.moblie = 3;
            this.msgList.push(msg);
            // this.postClickChangePhone();
            return false;
          } else {
            agree = e.status;
          }
        } else {
          type = 9;
          // if (e.isqq) {
          agree = 0;
          // } else {
          // 	agree = e.status;
          // }
        }
      } else if (e.type == "wxnum") {
        // console.log(this.numInfo.wxnum);
        if (!this.numInfo.wxnum && e.status == 1) {
          this.$prompt("请输入你的微信号", "请先填写你的微信号", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
          })
            .then(({ value }) => {
              if (this.usertype == 1) {
                this.updateUserBaseInf(value);
              } else {
                this.updateCompWxNum(value);
              }
            })
            .catch(() => {});
          return;
        }
        content =
          e.status == 1
            ? "[交换微信]"
            : e.status == 2
            ? "[微信]"
            : "已拒绝交换微信";
        new_content =
          e.status == 1
            ? "[交换微信]"
            : e.status == 2
            ? "[微信]"
            : "已拒绝交换微信";
        type = 4;
        if (this.usertype == 1) {
          if (e.isqq) {
            var msgs = {
              from: this.leftinfo.uid,
              to: this.rightinfo.uid,
              type: "4",
              type_agree: "1",
              content: "[交换微信]",
              time: this.msgList[this.msgList.length - 1].time,
              timeStr: this.msgList[this.msgList.length - 1].timeStr,
            };
            this.btnsStatus.wxnum = 3;
            this.msgList.push(msgs);
            return false;
          } else {
            agree = e.status;
          }
        } else {
          agree = 0;
        }
      }
      // console.log('’；；；；；', new_content);
      sedChatMsg({
        type: type,
        chat_type: "normal",
        content: content,
        agree: agree,
        new_content: JSON.stringify(new_content),
        job_id: this.job_id ? this.job_id : "",
        to_uid: this.uid,
      })
        .then((result) => {
          console.log("发送消息", result);
          if (result.code == 200) {
            console.log(result);
          } else if (result.code == 201) {
            this.$confirm(
              "您当前账号暂不支持回复消息 ，请升级VIP会员或联系客服",
              "温馨提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.$router.push(`/vippaypage`);
              })
              .catch(() => {});
          } else if (result.code == 202) {
            this.$confirm(result.msg, "未购买开放该职位", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push(`/vippaypage`);
              })
              .catch(() => {});
          } else if (result.code == 203) {
            this.$confirm(
              "请升级VIP会员或购买相对应的职位开放权限，才可以联系TA 。",
              "请升级VIP会员",
              {
                confirmButtonText: "立即升级",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.$router.push(`/vippaypage`);
              })
              .catch(() => {});
          } else if (result.code == 204) {
            this.$confirm(
              "您今天的直聊权益已用完，购买当日畅聊权益，今日不限聊！",
              "当日畅聊权益",
              {
                confirmButtonText: "立即购买",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.payInf = {
                  name: "当日畅聊卡",
                  use: "当日",
                  service_price: "6",
                  detail_id: 0,
                };
                this.payDialogTitle = "当日畅聊";
                this.purchaseType = "33";
                this.payDialogShowFun();
              })
              .catch(() => {});
          }
          // else {
          //   this.isCanChat = true;
          // }
        })
        .catch(() => {});
      // api.post({
      //   // url: 'Chat/chatLog',
      //   url: "Wschat/sendmsg",
      //   data: {
      //     type: type,
      //     chat_type: "normal",
      //     content: content,
      //     agree: agree,
      //     new_content: JSON.stringify(new_content),
      //     job_id: this.job_id ? this.job_id : "",
      //     to_uid: this.uid,
      //   },
      //   success: (res) => {
      //     if (res.code == 200) {
      //       console.log(res);
      //     } else {
      //       this.isCanChat = true;
      //     }
      //     // if (e.status == 0) {
      //     // } else {
      //     // }
      //     // var data = {
      //     // 	type: type,
      //     // 	chat_type: 'normal',
      //     // 	content: content,
      //     // 	agree: agree,
      //     // 	new_content: new_content,
      //     // 	job_id: this.job_id ? this.job_id : '',
      //     // 	to_uid: this.uid
      //     // };
      //     // this.changeStatusNum({
      //     // 	type: type,
      //     // 	status: agree
      //     // });
      //     // if (e.type == 'moblie') {
      //     // 	if (this.usertype == 1) {
      //     // 	}
      //     // } else if (e.type == 'wxnum') {
      //     // 	if (this.usertype == 1) {
      //     // 		if (e.isqq) {
      //     // 			agree = 1;
      //     // 		}
      //     // 	}
      //     // }
      //     // console.log('交换电话&微信', res, data);
      //   },
      // });
    },

    //获取常用语列表
    getChatCyyList() {
      getChatCyyList()
        .then((result) => {
          console.log("获取常用语列表", result);
          this.cyyList = result.data.list;
        })
        .catch(() => {});
    },

    showCyyDialogFun() {
      this.cyyDialogShow = true;
      // getChatCyyList()
      //   .then((result) => {
      //     console.log("获取常用语列表", result);
      //     this.cyyDialogShow = true;
      //     this.cyyList = result.data.list;
      //   })
      //   .catch(() => {});
    },
    addCyyFun() {
      this.$prompt("请输入常用语", "添加常用语", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          addChatCyyList({ content: value })
            .then((result) => {
              console.log("添加常用语", result);
              this.$message({
                message: "添加成功！",
                type: "success",
              });
              this.getChatCyyList();
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    editCyyFun(item) {
      this.$prompt("请输入常用语", "编辑常用语", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: item.content,
      })
        .then(({ value }) => {
          editChatCyyList({ id: item.id, content: value })
            .then((result) => {
              this.$message({
                message: "编辑成功！",
                type: "success",
              });
              this.getChatCyyList();

              console.log("编辑常用语", result);
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    delChatCyyList(item) {
      this.$confirm("此操作将永久删除该地址, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delChatCyyList({ ofen_id: item.id })
            .then((result) => {
              if (result.code == 200) {
                this.$message({
                  message: "删除成功！",
                  type: "success",
                });
                this.getChatCyyList();
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    setScroll() {
      // let that = this;
      var list = this.msgList;
      this.$nextTick(function () {
        // setTimeout(function() {
        // that.toView = "msg_" + (list.length - 1);
        // let target = document.getElementById("msg_" + (list.length - 1));
        // console.log("9999", target);
        // target.scrollTop(0,target.offsetTop);
        document.getElementById("msg_" + (list.length - 1)).scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      });
      console.log("滚动");
      // }, 500);
    },

    uploadSuccess(e) {
      if (e.code === 0) {
        return this.$message.error(e.msg);
      }
      var reqimgurl = e.data.httpCosUrl;
      var file = {
        type: "img",
        url: reqimgurl,
      };
      this.sedChatMsg("[图片]", file, 6);
    },

    /* 发职位 */
    showChoseJobDialogFun() {
      if (this.rightinfo.jobs.length == 0) {
        getChatJobList({ uid: this.uid })
          .then((result) => {
            // console.log("可发布的职位", result);
            var joblist = result.data.jobs;
            if (joblist.length == 0) {
              if (result.data.all_jobs.length == 0) {
                this.$confirm(
                  "您目前没有招聘中的职位，请先去发布职位！",
                  "提示",
                  {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                  }
                )
                  .then(() => {
                    this.$router.push("/jobmanage");
                  })
                  .catch(() => {});
              } else {
                result.data.all_jobs.forEach((i) => {
                  if (i.status == 0) {
                    this.$confirm(
                      "您发布的职位后台正在加紧审核中，先跟TA聊聊吧！",
                      "提示",
                      {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                      }
                    )
                      .then(() => {
                        this.$router.push("/jobmanage");
                      })
                      .catch(() => {});
                  } else {
                    this.$message({
                      message: "你的职位已下架，先上架职位再邀请面试吧！",
                      type: "warning",
                    });
                    setTimeout(() => {
                      this.$router.push("/jobmanage");
                    }, 2000);
                  }
                });
              }
            }
          })
          .catch(() => {});
      } else {
        this.$confirm("您确定要发送职位吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let range = [];
            this.rightinfo.jobs.forEach((item) => range.push(item.name));
            this.jobsrange = this.rightinfo.jobs;
            this.sendJobDialogShow = true;
            this.sendJobInf = this.rightinfo.jobs[0];
          })
          .catch(() => {});
        // console.log(this.jobsrange);
      }
    },

    /* 发送职位 */
    sendJobInfMsg() {
      let str = "职位邀请";
      let data = {
        id: this.sendJobInf.id,
        job: true,
        jobcontent: this.sendJobInf,
      };
      this.sedChatMsg(str, data, 2);
      this.sendJobDialogShow = false;
    },

    choseSendJob(item) {
      this.sendJobInf = item;
    },

    /* 约面试 */

    interviewUserToCom(stat) {
      console.log(stat);
      if (stat == 2) {
        return;
      } else if (stat == 3) {
        this.$confirm("您已申请面试，是否继续申请？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {})
          .catch(() => {});
        // uni.showModal({
        //   content: "您已申请面试，是否继续申请？",
        //   cancelText: "取消",
        //   confirmText: "确认",
        //   success: (res) => {
        //     if (res.confirm) {
        //       uni.navigateTo({
        //         url: url,
        //       });
        //     }
        //   },
        // });
      } else {
        this.$confirm("您确定要约面试吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.interviewDialogShow = true;
            this.$nextTick(() => {
              this.$refs.datePick.focus();
              this.$refs.startTimePicker.focus();
              this.$refs.endTimePicker.focus();
            });
            this.getCanInterviewJobList();
          })
          .catch(() => {});

        // uni.setStorageSync("fbsenc", "chatinter");
        // uni.navigateTo({
        //   url: url,
        // });
      }
    },

    /* 发送表情 */
    sendEmoji(data) {
      let str = this.sendTextareaText;
      var msglen = this.sendTextareaText.length - 1;
      if (data.msg && data.msg != "[del]") {
        str = str + data.msg;
      } else if (data.msg == "[del]") {
        let start = str.lastIndexOf("[");
        let end = str.lastIndexOf("]");
        let len = end - start;
        if (end != -1 && end == msglen && len >= 3 && len <= 4) {
          str = str.slice(0, start);
        } else {
          str = str.slice(0, msglen);
        }
      }
      this.sendTextareaText = str;
    },
    chooseDate() {
      this.$refs.datePick.focus(); // 选择日期后依旧触发焦点
      this.$refs.startTimePicker.focus();
      this.$refs.endTimePicker.focus();
    },
    /* 获取可面试的职位 */
    getCanInterviewJobList() {
      getChatJobList({ uid: this.uid })
        .then((result) => {
          console.log("获取可面试的职位", result);
          let joblist = result.data.jobs;
          if (joblist.length == 0) {
            if (result.data.all_jobs.length == 0) {
              this.$confirm("您还没有发布职位,即将跳转发布职位~", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  this.$router.push("/releasejob");
                })
                .catch(() => {});
            } else {
              try {
                result.data.all_jobs.forEach((i) => {
                  if (i.status == 0) {
                    // this.showmodel = true;
                    // throw new Error("exc");
                  } else {
                    this.$confirm(
                      "你的职位已下架，先上架职位再邀请面试吧！",
                      "提示",
                      {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                      }
                    )
                      .then(() => {
                        this.$router.push("/jobmanage");
                      })
                      .catch(() => {});

                    throw new Error("exc");
                  }
                });
              } catch (e) {
                //TODO handle the exception
              }
            }
          } else {
            let address = joblist[0].address;
            address = address ? address : joblist[0].province + joblist[0].city;
            this.interviewMsgObj.address = address;
            // 只有一份工作, 不用选
            if (joblist.length == 1) {
              this.interviewMsgObj.position = joblist[0].name;
              this.interviewMsgObj.jobid = joblist[0].id;
            }
            // 职位列表
            this.joblist = joblist;
          }
        })
        .catch(() => {});
    },

    choseInterviewJob(id) {
      console.log("点点点", id);
      this.interviewMsgObj.jobid = id;
    },
    /* 发送面试消息 */

    sendInterviewMsg() {
      if (
        this.interDay == "" ||
        this.interStaTime == "" ||
        this.interEndTime == ""
      ) {
        this.$message({
          message: "请选择面试时间",
          type: "warning",
        });
        return false;
      }

      if (this.interviewMsgObj.jobid == 0) {
        this.$message({
          message: "请选择职位",
          type: "warning",
        });
        return false;
      }

      var tist1 = new Date(
        this.interDay + " " + this.interStaTime + ":00"
      ).getTime();
      var tist2 = new Date(
        this.interDay + " " + this.interEndTime + ":00"
      ).getTime();
      console.log(tist1, tist2);
      this.interviewMsgObj.intertime = tist1 + "-" + tist2;
      this.interviewMsgObj.uid = this.uid;
      this.interviewMsgObj.content = "";
      this.interviewMsgObj.linkman = "";
      this.interviewMsgObj.linktel = "";
      // if (this) {
      //   this.$message({
      //     message: "请选择职位",
      //     type: "warning",
      //   });
      //   return false;
      // }
      sendInterview(this.interviewMsgObj)
        .then((result) => {
          console.log("面试邀请", result);
          if (result.code == 200) {
            let data = {
              interview_id: result.data.interview_id,
              send_interview: true,
            };
            this.$nextTick(() => {
              this.$refs.datePick.blur();
              this.$refs.startTimePicker.blur();
              this.$refs.endTimePicker.blur();
            });
            this.sedChatMsg("[面试邀请]", data, 5);
            this.getChatChangedInf(this.uid);
            this.interviewDialogShow = false;
          }
        })
        .catch(() => {});
    },

    /* 选择位置 */

    submitAddress(e) {
      if (!e) {
        this.$message({
          message: "请选择地址",
          type: "warning",
        });
        return false;
      }
      console.log("选地址", e);
      var addrinf = {};
      addrinf.name = e.address1;
      addrinf.latitude = e.y;
      addrinf.longitude = e.x;
      addrinf.address = e.address1;

      this.sedChatMsg("[位置]", addrinf, 8);
      this.choseAddrDialogShow = false;
    },

    /* 发送简历 */
    sendResume() {
      this.$confirm("确定要发送简历给对方吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deliverResume(this.leftinfo.jobs[0].id, this.leftinfo.uid, 0)
            .then((result) => {
              console.log("发送简历", result);
              if (result.code == 200 && result.msg != "您已申请该职位") {
                this.sendJanli();
              } else {
                this.$message({
                  message: "您已发送简历,无需重发送申请~",
                  type: "warning",
                });
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    //发送简历
    sendJanli() {
      sedChatMsg({
        type: "10",
        content: "[发送简历]",
        to_uid: this.uid,
        chat_type: "normal",
        agree: 0,
        job_id: this.job_id ? this.job_id : "",
        new_content: "[发送简历]",
      })
        .then((result) => {
          console.log("发送消息", result);
          this.setScroll();
        })
        .catch(() => {});

      // api.post({
      // 	// url: 'Chat/chatLog',
      // 	url: 'Wschat/sendmsg',
      // 	data: {
      // 		type: '10',
      // 		chat_type: 'normal',
      // 		content: '[发送简历]',
      // 		agree: 0,
      // 		new_content: '[发送简历]',
      // 		job_id: this.job_id ? this.job_id : '',
      // 		to_uid: this.uid
      // 	},
      // 	success: res => {
      // 		if (res.code == 200) {
      // 			this.getChatBtnStatus();
      // 		} else {
      // 			this.isCanChat = true
      // 		}
      // 		// console.log('发送简历', res);
      // 	}
      // });
    },
    claseDia() {
      console.log("关闭弹窗.....");
      this.choseAddrDialogShow = false;
      this.mapShowDialogShow = false;
    },
    claseDia2() {
      this.choseAddrDialogShow = false;
    },
  },
};
</script>

<style>
.chat-page {
  /* display: flex; */
}
.chat-page .el-dialog__wrapper .el-dialog {
  border-radius: 15px;
}

.chat-navbar-view {
  width: 1190px;
  height: 33px;
  background-color: #fff;
  border-radius: 2px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding: 3px;
}

.chat-navbar-view .nav {
  padding: 4px 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #191919;
  line-height: 21px;
  border-radius: 4px;
}

.chat-navbar-view .nav-sl {
  background-color: #ffe7d9;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #ff8136;
}

.chat-navbar-view .nav .el-badge__content.is-fixed {
  top: -8px;
  right: 10px;
}
.remar-dialog-view {
  border-top: 1px solid #ddd;
  padding: 0 24px;
}

.remar-dialog-view .mk-title {
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 20px;
  color: #191919;
  margin-top: 30px;
}
.remar-dialog-view .remar-tag-view span {
  display: inline-block;
  background-color: #f5f6fa;
  border-radius: 4px;
  padding: 6px 12px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #757575;
  margin-top: 22px;
  margin-right: 22px;
}
.remar-dialog-view .remar-tag-view .remar-tag-sl {
  border: 1px solid #fe6002;
  color: #fe6002;
  background-color: rgba(254, 96, 2, 0.15);
}
.remar-dialog-view .remar-tag-view .add-sp {
  border: 1px solid #fe6002;
  color: #fe6002;
  background-color: #fff;
}
.remar-dialog-view .texter-view {
  background-color: #f5f6fa;
  border-radius: 8px;
  margin-top: 16px;
}

.remar-dialog-view .texter-view .el-textarea__inner {
  border: 0;
  resize: none;
  background-color: #f5f6fa;
}
.remar-dialog-view .btn-view {
  width: 100%;
  text-align: right;
  margin-top: 43px;
  padding-bottom: 33px;
}
.remar-dialog-view .btn-view .el-button {
  width: 160px;
}
.chat-left-list-view {
  width: 380px;
  /*height: 700px;*/
  height: 85vh;
  background-color: #fff;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  overflow: scroll;
}
.chat-left-list-view .chat-list-keyword-input {
  width: 343px;
  height: 40px;
  margin-top: 12px;
  margin-left: 19px;
}
.chat-left-list-view .chat-list-keyword-input .el-input__inner {
  border: none;
  background-color: #f5f6fa;
  border-radius: 6px 6px 6px 6px;
}

.chat-left-list-view .chat-item {
  display: flex;
  padding: 18px 12px;
  position: relative;
}

.chat-left-list-view .chat-item-sl {
  background-color: #fff5f0;
}
.chat-left-list-view .chat-item .el-badge__content.is-fixed {
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 99;
}
/* .chat-left-list-view .chat-item .badge-num sup.el-badge__content.is-fixed {
  position: absolute;
  top: 10px;
  margin-left: -100px;
} */
.chat-left-list-view .chat-item .user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.chat-left-list-view .user-name-newcont {
  margin-left: 12px;
  margin-top: 3px;
  z-index: 0;
}

.chat-left-list-view .user-name-newcont .user-name {
  display: flex;
  position: relative;
}

.chat-left-list-view .user-name-newcont .user-name .name {
  max-width: 212px;
  font-size: 17px;
  font-weight: 800;
  color: #191919;
  line-height: 19px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-left-list-view .user-name-newcont .user-name .bz-text {
  font-size: 10px;
  font-weight: 600;
  color: #fe6002;
  border: 1px solid#fe6002;
  padding: 4px 8px;
  margin-left: 5px;
  border-radius: 0 5px 0 5px;
}
.chat-left-list-view .user-name-newcont .new-conten {
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
  color: #303030;
  line-height: 16px;
  margin-top: 5px;
}
.chat-left-list-view .chat-time {
  width: 92px;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  color: #b1b1b1;
  line-height: 14px;
  margin-left: 6px;
  position: absolute;
  right: 0;
}

.sys-msg-view {
  background-color: #fff;
  width: 806px;
  height: 700px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  margin-left: 10px;
  overflow: scroll;
}

/* 右侧聊天样式 */
.chat-right-detail-view {
  background-color: #fff;
  width: 806px;
  /*height: 700px;*/
  height: 85vh;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  margin-left: 10px;
  /* overflow: scroll; */
}

.chat-right-detail-view-nonelist {
  background-color: #fff;
  width: 806px;
  /*height: 700px;*/
  height: 85vh;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chat-right-detail-view-nonelist .none-list-img {
  width: 260px;
  height: 208px;
}

.chat-right-detail-view-nonelist .none-text {
  font-size: 18px;

  font-weight: 400;
  color: #939597;
  line-height: 24px;
  margin-top: 12px;
}

.msg-ht-list {
  height: 60vh;
  overflow: scroll;
  width: 806px;
  margin: 10px 0;
}

.slot-wrap {
  position: absolute;
  right: 32px;
}

.head_box {
  /* width: 100%; */
  display: flex;
  /* padding-top: 20px; */
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #ffffff;
  /* top: 1000px */
  /* position: fixed; */
  z-index: 99;
}

.head_box > view {
  flex: 1;
  text-align: center;
}

.head_box text {
  display: block;
  font-size: 12px;

  font-weight: 400;
  color: #757575;
  margin-top: 10px;
}

.head_box .none_box image {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}

.head_box image {
  width: 54px;
  height: 52px;
}

.msg_page,
.top_page {
  position: absolute;
  bottom: 86px;
  left: 0;
  right: 0;
  margin: auto;
  background: #f5f6fa;
  /* width: 686px; */
}

.top_page {
  top: 0px;
}

.msg_page {
  top: 330px;
}

.placeholder {
  margin-left: 20px;
  font-size: 12px;

  font-weight: 400;
  color: #b1b1b1;
}

.times_msg {
  line-height: 86px;
  color: #b1b1b1;
  font-size: 14px;
  text-align: center;
}

.times_msg .chmsg {
  background-color: #c5c9cd;
  color: #ffffff;
  position: relative;
  padding: 8px 10px;
  border-radius: 4px;
  font-size: 12px;
}

.msg_list {
  /* margin-bottom: 60px; */
  margin: 0 38px 60px 32px;
}

.rightmsg,
.leftmsg {
  display: flex;
  position: relative;
}

.rightmsg {
  justify-content: flex-end;
}

.msg_list .logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.rightmsg .logo {
  /* margin-left: 12px; */
}

.leftmsg .logo {
  margin-right: 16px;
}

.msg_list .msg {
  max-width: 494px;
  padding: 10px 16px;
  font-size: 16px;
  position: relative;
  margin-top: 10px;

  font-weight: 400;
  line-height: 20px;
}

.msg_list .msg .tetx_box image {
  position: absolute;
  height: 22px;
  width: 10px;
  top: 30px;
}

.msg_list .rightmsg image {
  right: -10px;
}

.msg_list .leftmsg .msg > image {
  left: -10px;
  transform: rotate(180deg);
}

.msg_list .greenbj {
  /* color: #333333; */
  background: #ff8136;
  color: #fff;
  box-shadow: 0px 7px 14px -1px rgba(175, 180, 197, 0.16);
  border-radius: 6px 0px 6px 6px;
}

.msg_list .whitebj {
  color: #191919;
  /* background: #fff; */
  /* color: #fff; */
  background: #f5f6fa;
  border-radius: 0px 6px 6px 6px;
}

/* .rightmsg {
  background-color: #000000;
} */

.rightmsg .tetx_box,
.leftmsg .tetx_box {
  display: flex;
  flex-flow: wrap;
  align-items: center;
}

.rightmsg .tetx_box > div .emoji,
.leftmsg .tetx_box > div .emoji {
  width: 30px;
  height: 30px;
  position: static;
}

.receivedjh_box {
  display: flex;
  align-items: flex-end;
  position: relative;
  margin-top: -70px;
}

.receivedjh_box > div {
  width: 500px;
  background: #fff;
  border-radius: 8px;
  margin-left: 18px;
  box-shadow: 0px 10px 16px -4px rgba(175, 180, 197, 0.5);
}

.receivedjh_box .tops {
  padding: 64px 34px 72px;
  display: flex;
  align-items: center;
  font-size: 14px;

  font-weight: 400;
  color: #333333;
}

.receivedjh_box .tops img {
  width: 39px;
  height: 32px;
  margin-top: 14px;
}

.receivedjh_box .tops .txt {
  width: 365px;
  margin-left: 30px;
  font-size: 26px;
  color: #333333;
}

.receivedjh_box .btns {
  border-top: 1px solid #e7e8eb;
  display: flex;
}

.receivedjh_box .btns span {
  width: 50%;
  height: 68px;
  line-height: 68px;
  text-align: center;
  border-right: 1px solid #e7e8eb;
  font-size: 22px;

  font-weight: 400;
  color: #222222;
}

.receivedjh_box .btns span:last-child {
  color: #ff8136;
  border: 0;
}

.num_box {
  width: 274px;
  /* margin-left: 30px; */
  background: linear-gradient(to bottom, #fff5f0, #ffffff);
  margin: auto;
  /* margin-bottom: 60px; */
  border: 1px solid #fe6002;
  /* box-shadow: 0px 3px 7px -1px rgba(175, 180, 197, 0.16); */
}

.num_box .tops {
  display: flex;
  padding: 14px 0;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  font-weight: 400;
}
.num_box .tops span {
  color: #191919;
}
.num_box .tops label {
  color: #0294fe;
}
.num_box .tops div {
  display: flex;
  align-items: center;
}
.num_box .tops div div {
  color: #3291e1;
  font-size: 16px;
}

.num_box .btns {
  display: flex;
  height: 28px;
  line-height: 28px;
  font-size: 26px;
  border-top: 1px solid #e7e8eb;
  cursor: pointer;
}

.num_box .btns span {
  width: 100%;
  text-align: center;
  font-size: 14px;

  font-weight: 400;
  color: #ff8136;
}

.num_box .btns span:nth-child(2) {
  border-left: 1px solid #e7e8eb;
}

.num_box .noche {
  height: 84px;
  line-height: 84px;
  display: flex;
  font-size: 26px;
  border-top: 1px solid #e7e8eb;
}

.num_box .noche span {
  width: 100%;
  text-align: center;
  font-size: 12px;

  font-weight: 400;
  color: #cccccc;
}

.num_box .noche span:nth-child(2) {
  border-left: 1px solid #e7e8eb;
}

.msg_list .interview_box {
  display: flex;
  align-items: center;
}

.msg_list .interview_box img {
  height: 8px;
  width: 8px;
  position: static;
}

.msg_list .rightmsg .interview_box img {
  margin-left: 10px;
}

.msg_list .leftmsg .interview_box img {
  margin-right: 10px;
}

.msg_list .leftmsg .interview_box .icon {
  font-size: 32px;
}

.msg_list .video_box video,
.msg_list .video_box img {
  width: 344px;
  position: relative;
}

.msg_list .audio_box {
  width: 240px;
}

.msg_list .rightmsg .audio_box {
  text-align: right;
}

.msg_list .audio_box img {
  position: static;
  width: 13px;
  height: 17px;
  margin-left: 15px;
}

.msg_list .leftmsg .audio_box img {
  transform: rotate(180deg);
  margin-right: 15px;
  margin-left: 0;
}

.uninterested_box {
  padding: 0 32px;
  height: 70px;
  line-height: 70px;
  font-size: 24px;
  color: #ffaa32;
  background: #fff5ed;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.uninterested_box button {
  width: 120px;
  height: 46px;
  line-height: 46px;
  margin: 0;
  border-radius: 25px;
  color: #fff;
  background: #ffaa32;
  font-size: 24px;
}

.bz_shadow {
  height: 255px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.bz_shadow > view:first-child {
  border-bottom: 1px solid #e5e5ee;
}

.bz_shadow > view {
  height: 50%;
  display: flex;
  padding: 0 32px;
  align-items: center;
  font-size: 30px;
  color: #666;
}

.bz_shadow .img {
  height: 45px;
  width: 65px;
}

.bz_shadow .img image {
  height: 100%;
}

.bz_bzlist {
  padding: 40px 32px;
  height: 466px;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  position: absolute;
  bottom: 0;
}

.bz_bzlist .tit {
  line-height: 92px;
  font-size: 36px;
  color: 333333;
  font-weight: bold;
}

.bz_bzlist .li {
  display: flex;
  padding: 26px 0;
  color: #333;
}

.bz_bzlist image {
  width: 95px;
  height: 95px;
  margin-right: 25px;
}

.bz_bzlist .tits {
  font-size: 32px;
  font-weight: 600;
}

.bz_bzlist text {
  font-size: 28px;
}

.map_box {
  width: 100%;
  display: flex;
  justify-content: center;
}

.map_box .zcz-map {
  width: 509px;
  height: 255px;
  background: linear-gradient(to bottom, #fff5f0, #ffffff);
  border: 1px solid #fe6002;
}

.map_box .zcz-map .add-text-view {
  display: flex;
  align-items: center;

  font-weight: 400;
  font-size: 12px;
  color: #191919;
  line-height: 14px;
  margin-left: 24px;
  margin-top: 15px;
}

.map_box .zcz-map .add-text-view img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.map_box map {
  width: 430px;
  height: 280px;
}

.addbq_shadow {
  width: 500px;
  padding: 32px;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);
  background: #fff;
  border-radius: 20px;
  margin: auto;
}

.addbq_shadow image {
  float: right;
  width: 40px;
  height: 40px;
}

.addbq_shadow input {
  height: 50px;
  line-height: 50px;
  border-bottom: 2px solid #ff8136;
  font-size: 26px;
  padding: 30px 0;
  margin-top: 60px;
}

.addbq_shadow button {
  margin-top: 30px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  background: #ff8136;
  width: 100%;
  font-size: 26px;
}

.wx_shadow {
  width: 560px;
  height: 350px;
  border-radius: 28px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

.wx_shadow .top {
  padding: 32px;
  line-height: 50px;
  font-size: 22px;
  color: #333;
}

.wx_shadow .top view {
  text-align: center;
}

.wx_shadow .top .tit {
  line-height: 56px;
  font-size: 30px;
  font-weight: bold;
}

.wx_shadow input {
  width: 100%;
  height: 58px;
  line-height: 58px;
  border: 2px solid #999999;
  box-sizing: border-box;
  font-size: 22px;
  padding: 0 10px;
  border-radius: 8px;
  margin-top: 32px;
}

.wx_shadow button {
  height: 100px;
  line-height: 100px;
  border-top: 1px solid #f3f3f3;
  font-size: 30px;
  color: #ff8136;
  font-weight: bold;
  width: 100%;
  margin: 0;
}

.msg_list {
  position: relative;
}

.cz_box {
  position: absolute;
  top: -30px;
  right: 40px;
  background: #000000;
  border-radius: 4px;
  width: 185px;
  font-size: 12px;
  font-weight: 400;
  /* padding: 20px; */
  height: 31px;
  line-height: 31px;
}

#msg-0 .cz_box {
  top: inherit;
  bottom: -80px;
}

.cz_box .btns {
  display: flex;
  align-items: center;
}

.cz_box .btns .its {
  text-align: center;
  /* display: flex; */
  color: #fff;
  flex: 1;
}

.cz_box .btns .ddd {
  flex: 2;
}

.jobs_msg {
  width: 100%;
  display: flex;
  justify-content: center;
}

.qzphonekp {
  width: 504px;
  height: 268;
  margin-left: 30px;
  background: #fff;
  border-radius: 12px;
  margin: auto;
  margin-bottom: 60px;
  box-shadow: 0 20rpx 40rpx -2rpx rgba(175, 180, 197, 0.1);
}

.qzphonekp .tops {
  display: flex;
  padding: 44px 0 36px 0;
  align-items: center;
  justify-content: center;
  font-size: 26px;

  font-weight: 400;
}

.qzphonekp .tops image {
  width: 78px;
  height: 74px;
  margin-right: 22px;
}

.qzphonekp .tops text {
  font-size: 26px;
  color: #222222;
}

.qzphonekp .btns {
  width: 440px;
  height: 112px;
  line-height: 112px;
  display: flex;
  font-size: 26px;
  border-top: 1rpx solid #e7e8eb;
  margin-left: 26px;
}

.qzphonekp .btns text {
  width: 100%;
  text-align: center;
  font-size: 36px;

  font-weight: 500;
  color: #ff8136;
}

.statusright {
  display: flex;
  position: relative;
  font-size: 12px;
  color: #939597;
  margin-top: 2px;
  justify-content: flex-end;
  margin-right: 50px;
}

.wd-status {
  color: #ff8136;
}

.bj-resume {
  width: 686px;
  height: 230px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 20px 40px -2px rgba(175, 180, 197, 0.16);
  text-align: center;
}

.bj-resume .top-v {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 41px 0;
}

.bj-resume .top-v image {
  width: 36px;
  height: 36px;
  margin-right: 14px;
}

.bj-resume .bot-v {
  width: 622px;
  line-height: 110px;
  border-top: 2px solid#F3F3F3;
  margin-left: 38px;
  font-size: 36px;
  color: #222222;
  font-weight: 500;
}

/* 下面发送的样式 */
.bot-send-input-btn .func-btn-view {
  border-top: 2px solid#EAECF5;
  display: flex;
  align-items: center;
  padding: 13px 0;
}

.bot-send-input-btn .func-btn-view .chyo-img-btn {
  width: 27px;
  height: 24px;
  margin-left: 24px;
  cursor: pointer;
}
.bot-send-input-btn .func-btn-view .em-img-btn {
  width: 24px;
  height: 24px;
  margin-left: 16px;
  cursor: pointer;
}

.bot-send-input-btn .func-btn-view .change-btn-img {
  width: 72px;
  height: 23px;
  margin-left: 16px;
  cursor: pointer;
}
.bot-send-input-btn .func-btn-view .chat-act-view {
  width: 72px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #41d7b2;
  border-radius: 4px;
  margin-left: 16px;
}

.bot-send-input-btn .func-btn-view .chat-act-view:first-child {
  margin-left: 24px;
}
.bot-send-input-btn .func-btn-view .send-phone {
  border: 1px solid #ff880d;
}
.bot-send-input-btn .func-btn-view .send-resume {
  border: 1px solid #5d8af2;
}
.bot-send-input-btn .func-btn-view .send-job {
  border: 1px solid #ffb118;
}
.bot-send-input-btn .func-btn-view .send-addr {
  border: 1px solid #31d0c8;
}
.bot-send-input-btn .func-btn-view .set-bz {
  border: 1px solid #945eec;
}
.bot-send-input-btn .func-btn-view .chat-act-view img {
  width: 16px;
  height: 16px;
}

.bot-send-input-btn .func-btn-view .chat-act-view span {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #303030;
  line-height: 14px;
  margin-left: 5px;
}

.chat-page .input-view {
  /*height: 170px;*/
  height: 15vh;
  padding: 5px;
  position: relative;
  background-color: white;
}

.chat-page .input-view .el-textarea__inner {
  border: 0;
  resize: none;
  background-color: none;
}

.chat-page .input-view .send-btn {
  position: absolute;
  right: 20px;
  bottom: 10px;
}
.chat-page .input-view .el-button {
  width: 60px;
  height: 35px;
  font-size: 13px;
}

.pop-cyy-view .pop-cyy-title {
  padding: 5px 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fffaf7;
}
div#el-popover-4479 {
  padding: 0;
}

.pop-cyy-view .pop-cyy-title .title {
  font-size: 12px;

  font-weight: 500;
  color: #303030;
  line-height: 14px;
}

.pop-cyy-view .pop-cyy-title .set-text {
  font-size: 12px;

  font-weight: 400;
  color: #fe6002;
  line-height: 14px;
  cursor: pointer;
}

.pop-cyy-view .cyy-item-view {
  padding: 6px 10px;
}

.pop-cyy-view .cyy-item-view .item {
  display: flex;
  align-items: center;
  /* margin-top: 10px; */
  padding: 10px 0;
}

.pop-cyy-view .cyy-item-view .item .dian {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #cccccc;
}

.pop-cyy-view .cyy-item-view .item .text {
  font-size: 14px;

  font-weight: 400;
  color: #191919;
  line-height: 16px;
  margin-left: 4px;
}
/* 下面发送的样式 */

/* 右侧聊天样式 */

.cyy-dialog-list-view {
  padding: 27px 27px;
  border-top: 1px solid #eaecf5;
}

.cyy-dialog-list-view .cyy-dialog-item-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 0;
}
.cyy-dialog-list-view .cyy-dialog-item-view .cyy-item-left-view {
  display: flex;
  align-items: center;
}
.cyy-dialog-list-view .cyy-dialog-item-view .cyy-item-left-view .dian {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #cccccc;
}
.cyy-dialog-list-view .cyy-dialog-item-view .cyy-item-left-view .text {
  font-size: 18px;

  font-weight: 400;
  color: #222222;
  line-height: 24px;
  margin-left: 8px;
}

.cyy-dialog-list-view .cyy-dialog-item-view .right-act-img-btn-view i {
  font-size: 18px;
  color: #303030;
  margin-left: 24px;
}

.cyy-dialog-add-btn {
  margin-top: 30px;
}

.inv-dialog-dialog {
  min-height: 600px;
}

.dialog-title-view {
  display: flex;
  align-items: center;
}

.dialog-title-view .left-xian-view {
  display: inline-block;
  width: 4px;
  height: 20px;
  background-color: #fe6002;
  border-radius: 3px;
}
.dialog-title-view .text {
  font-size: 22px;

  font-weight: 600;
  color: #222222;
  line-height: 28px;
  margin-left: 15px;
}
.interview-dialog {
  border-top: 1px solid#EAECF5;
  display: flex;
  padding: 16px 22px;
}
/* dialog-tiem-chose-view */

.interview-dialog .dialog-tiem-chose-view .el-date-editor {
  margin-top: 20px;
}
.interview-dialog .dialog-tiem-chose-view .el-date-editor--date {
  width: 400px;
}

.interview-dialog .dialog-tiem-chose-view .el-date-editor--time-select {
  width: 130px;
  margin-left: 30px;
}
.interview-dialog .dialog-chose-job-view {
  margin-left: 36px;
}
.interview-dialog .dialog-chose-job-view .job-item {
  margin-top: 16px;
  width: 146px;
  height: 22px;
  padding: 9px 16px;
  background-color: #f5f6fa;
  border-radius: 2px;

  font-weight: 400;
  font-size: 16px;
  color: #191919;
  line-height: 19px;
}

.interview-dialog .dialog-chose-job-view .is-sl {
  border: 1px solid #fe6002;
  background-color: #fff5f0;
}

.interview-dialog .dialog-chose-job-view .qr-btn {
  margin-top: 70px;
}

.interview-dialog .dialog-chose-job-view .qr-btn .el-button {
  width: 240px;
  height: 52px;
  border-radius: 8px;

  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
}

.dialog-job-list {
  padding: 20px;
}

.dialog-job-list .job-item {
  font-size: 16px;

  font-weight: 400;
  color: #191919;
  line-height: 19px;
  margin-top: 16px;
  background-color: #f5f6fa;
  width: 95%;
  height: 50px;
  border-radius: 2px;
  line-height: 50px;
  padding-left: 16px;
}

.dialog-job-list .is-chose-item {
  background-color: #fff5f0;
  border: 1px solid#FE6002;
}
.dialog-bot-btn-vivew {
  display: flex;
  align-items: center;
  border-top: 1px solid #e7e8eb;
  padding: 14px 0;
  font-size: 20px;

  font-weight: 400;
  color: #191919;
}

.dialog-bot-btn-vivew .btn-view {
  flex: 1;
  text-align: center;
  cursor: pointer;
}
.dialog-bot-btn-vivew .conf-btn {
  border-left: 1px solid#e7e8eb;
  color: #fe6002;
}

.chat-page .natile {
  font-size: 32px;
  font-weight: 500;
  margin-left: 100px;
}

.chat-page .navbar-right {
  margin-right: 24px;
  display: flex;
  font-size: 12px;

  font-weight: 400;
  color: #757575;
}

.chat-page .right-item {
  margin: 0 4px;
  position: relative;
  color: #757575;
  display: flex;
}
.chat-page .list2,
.chat-page .list {
  background-color: #ffffff;
  margin: 0 20px;

  margin-bottom: 12px;
  padding-top: 16px;
  border-bottom: 12px solid #f5f6fa;
  padding: 0 10px;
}
.chat-page .list2 .ld-img,
.chat-page .list .ld-img {
  width: 22px;
  height: 22px;
}
.chat-page .list2 .tit,
.chat-page .list .tit {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  position: relative;
}
.chat-page .list2 .tit .ic,
.chat-page .list .tit .ic {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
}
.chat-page .list2 .tit .tz,
.chat-page .list .tit .tz {
  font-size: 20px;

  font-weight: 500;
  color: #222222;
  margin-left: 8px;
}
.chat-page .list2 .text,
.chat-page .list .text {
  margin-top: 10px;
}
.chat-page .list2 .text,
.chat-page .list .text {
  font-size: 19px;
  line-height: 34px;

  font-weight: 400;
  color: #757575;
}

.chat-page .el-badge__content {
  margin-top: 10px;
}

.chat-page .todetails {
  display: flex;
  justify-content: space-between;
  height: 34px;
  line-height: 34px;
  align-items: center;
  /* border-top: 1px solid #e7e8eb; */
  margin-top: 10px;
  position: relative;
}

.chat-page .todetails text {
  font-size: 19px;

  font-weight: 400;
  position: absolute;
  left: 0;
  /* color: #fe6002; */
}

/* .chat-page .todetails image {
  display: block;
  width: 12px;
  height: 20px;
  margin-left: 20px;
} */

.chat-page .yy {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.chat-page .tit .tz {
  position: relative;
  max-width: 500px;
}

.chat-page .tit .tz.current::after {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #e60012;
  position: absolute;
  right: -16px;
  top: 6px;
}

.chat-page .time {
  font-size: 19px;

  font-weight: 400;
  color: #939597;
}
.msg-detail {
  min-height: 300px;
  padding: 30px;
  font-size: 20px;
}
</style>